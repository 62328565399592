import useTranslator from "../../hooks/useTranslator";

const EmailField = (props) => {
  const { userLogin } = props;

  const { _t } = useTranslator();

  return (
    <>
      <div className="auth-email-div">
        <img className="mail-icon" src="/images/mail.svg" alt="" />
        <div className="auth-email-div-inner">
          <label className="auth-email-div-label">{_t("yourEmail")}</label>
          <p className="auth-email-div-p">{userLogin?.email}</p>
        </div>
      </div>
    </>
  );
};

export default EmailField;
