import TenantDetails from "../../../components/TenantHome/TenantDetails";
import TenantProfileChecklist from "../../../components/TenantHome/TenantProfileChecklist";
import useTranslator from "../../../hooks/useTranslator";
import TenantAlreadyRenting from "../../../components/TenantHome/TenantAlreadyRenting";
import TenantSkor from "../../../components/TenantHome/TenantSkor";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import { haveValue } from "../../../helper/common";
import { useCookies } from "react-cookie";
import TenantContext from "../../../context/tenant/TenantContext";
import { useNavigate } from "react-router-dom";
import AcceptGuarantor from "../../../components/AcceptGuarantor";
import AlreadyGuarantorTo from "../../../components/AlreadyGuarantorTo";
import AlreadyGuarantorOf from "../../../components/AlreadyGuarantorOf";
import TenantRentalDetails from "../../../components/TenantHome/TenantRentalDetails";
import axiosInstance from "../../../services/authWrapper";

const TenantHomeNew = () => {
  const navigate = useNavigate();
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { shareProfile, userType } = tenantContext;
  const { user, loadUserTenant } = authContext;

  const [showConfirmGuarantor, setShowConfirmGuarantor] = useState(false);
  const [alreadyGurantorTo, setAlreadyGurantorTo] = useState(false);
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);

  useEffect(() => {
    const user_type = localStorage.getItem("usertype");
    const userId = localStorage.getItem("saveduserid");
    if (haveValue(cookies.requestProfileLink) && user_type == "tenant") {
      if (haveValue(userId)) {
        // if (user?.docSigned) {
        shareProfile(userId, cookies.requestProfileLink, "null", "full", {
          shareType: "lLink",
        })
          .then((res) => {
            removeCookie("requestProfileLink", { maxAge: 1800 });
          })
          .catch((err) => {});
        // } else {
        //   navigate("/profile-shared?agreement=true");
        // }
      }
    }

    if (haveValue(cookies.gurantorFor) && user_type == "tenant") {
      if (
        haveValue(userId) &&
        !haveValue(user?.gurantor_for) &&
        !haveValue(user?.gurantor_id)
      ) {
        setShowConfirmGuarantor(true);
      }
      if (haveValue(user?.gurantor_for)) {
        setAlreadyGurantorTo(true);
      }
      if (haveValue(user?.gurantor_id)) {
        setAlreadyGurantorOf(true);
      }
    }
  }, []);

  useEffect(() => {
    if (user?.kyc_success && !haveValue(user?.paymentScores)) {
      console.log("GOING IN");
      getAnalysisDetails();
    }
  }, [user]);

  useEffect(() => {
    loadUserTenant("tenant");
    var timer = setInterval(() => {
      loadUserTenant("tenant");
    }, 10000);

    return () => {
      if (haveValue(timer)) clearInterval(timer);
    };
  }, []);

  const getAnalysisDetails = async (req, res) => {
    let userid = localStorage.getItem("saveduserid");
    console.log("ing getAnalysisDetails");
    let myInterval = setInterval(async () => {
      console.log("ing in loop");
      await axiosInstance
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          clearInterval(myInterval);
          loadUserTenant(userType);
        })
        .catch((err) => {
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };

  return (
    <>
      <TenantDetails />

      {user?.profile_completed && <TenantSkor showControls={true} />}

      {user?.rent_paid && <TenantRentalDetails showBig={true} />}

      <TenantProfileChecklist />

      <TenantAlreadyRenting />

      {showConfirmGuarantor && (
        <AcceptGuarantor
          setShowConfirmGuarantor={setShowConfirmGuarantor}
          showConfirmGuarantor={showConfirmGuarantor}
        />
      )}
      {alreadyGurantorOf && (
        <AlreadyGuarantorOf
          alreadyGurantorOf={alreadyGurantorOf}
          setAlreadyGurantorOf={setAlreadyGurantorOf}
          gurantorPresent={false}
          setGurantorPresent={(e) => console.log(e)}
        />
      )}
      {alreadyGurantorTo && (
        <AlreadyGuarantorTo
          alreadyGurantorTo={alreadyGurantorTo}
          setAlreadyGurantorTo={setAlreadyGurantorTo}
          gurantor_details={user?.gurantor_for}
        />
      )}
    </>
  );
};

export default TenantHomeNew;
