import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import TenantContext from "../../../context/tenant/TenantContext";
import AuthContext from "../../../context/auth/AuthContext";
import LandLordContext from "../../../context/landlord/LandLordContext";
import Spinner from "../../../components/Spinner";
import Search from "../../../components/Search";

import searchicon from "../../../assets/img/s.png";
import locationicon from "../../../assets/img/location.png";
import nexticon from "../../../assets/img/back.png";
import checkicon from "../../../assets/img/check.png";
import backicon from "../../../assets/img/back.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { calculateNowTime } from "../../../services/sharedService";
import useTranslator from "../../../hooks/useTranslator";
import {
  convertCurrencyFormat,
  haveValue,
  getBaseUrl,
} from "../../../helper/common";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";
import { FaRegCopy } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import copy from "copy-to-clipboard";
import QRCode from "react-qr-code";
import { Slider, styled } from "@mui/material";

function downloadBlob(blob, filename) {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

const ProfileRequest = () => {
  const { _t } = useTranslator();
  const RentSlider = styled(Slider)({
    color: "#A3EB64",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "7px solid #253837",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&::before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 9,
      background: "unset",
      padding: 0,
      width: 38,
      height: 38,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&::before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  const marks = [
    {
      value: 1,
      label: "1%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const {
    loadUserTenant,
    user,
    userType,
    loadMyNotifications,
    updateUserInfo,
    checkIeDetails,
  } = authContext;

  const tenantContext = useContext(TenantContext);
  const { shareProfile } = tenantContext;

  const landLordContext = useContext(LandLordContext);
  const {
    loadProfilesLandlord,
    fullProfileReceived,
    basicProfileReceived,
    properties,
    loadProperties,
  } = landLordContext;
  const [propertyFilter, setPropertyFilter] = useState("all");
  const [searchVal, setSearchVal] = useState("");
  const [rentPercentage, setRentPercentage] = useState(
    user?.rent_percentage ?? 1
  );
  const [rentValFrom, setRentValFrom] = useState(0);
  const [rentDoc, setRentDoc] = useState("current_annual_income");
  const [recordedSalary, setRecordedSalary] = useState({
    salary: 0,
    matched: 0,
  });

  const queryParameters = new URLSearchParams(window.location.search);

  //toggling between full and basic profile
  const [loading, setLoading] = useState(false);
  const [filteredFullProfiles, setFilteredFullProfiles] = useState([]);
  const [filteredBasicProfiles, setFilteredBasicProfiles] = useState([]);

  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);
  const [isChanged, setIsChanged] = useState(false);

  const [filters, setFilters] = useState({
    createdAt: "desc",
    myScore: "",
  });

  useEffect(() => {
    let userid = localStorage.getItem("saveduserid");
    if (haveValue(user?._id) || haveValue(userid)) {
      useeffectfunc();
    }
    loadProperties(userid);
  }, [filters]);

  useEffect(() => {
    if (haveValue(user?.rent_percentage)) {
      setRentPercentage(user?.rent_percentage);
    }
    if (haveValue(user?.rent_from)) {
      setRentDoc(user?.rent_from);
    }
  }, [user]);

  useEffect(() => {
    if (isChanged) handleChangeRent();
  }, [rentDoc, rentPercentage]);

  const useeffectfunc = async () => {
    const sharedProfileLink = queryParameters.get("sharedProfileLink");
    const navigateTo = queryParameters.get("navigateTo");
    if (haveValue(navigateTo)) {
      navigate(`/${navigateTo}`);
    }
    if (haveValue(sharedProfileLink)) {
      await importProfileRedirect();
    } else {
      loadData();
    }
  };

  const loadData = () => {
    let userid = localStorage.getItem("saveduserid");
    if (user && user?._id) {
      setLoading(true);
      loadProfilesLandlord(user?._id, "full", filters)
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      // loadProfilesLandlord(user?._id, "basic");
    } else if (haveValue(userid)) {
      setLoading(true);
      loadProfilesLandlord(userid, "full", filters)
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      // loadProfilesLandlord(userid, "basic");
    }

    loadMyNotifications(userid, userType);
  };

  const importProfileRedirect = async () => {
    let userid = localStorage.getItem("saveduserid");
    const sharedProfileLink = queryParameters.get("sharedProfileLink");

    if (haveValue(sharedProfileLink)) {
      const done = await shareProfile(
        sharedProfileLink,
        userid,
        "null",
        "full",
        { shareType: "tLink" }
      );
      removeCookie("sharedProfileLink", { maxAge: 1800 });
      if (done) {
        toast.success(_t("profileImported"));
      }
      loadData();
      navigate("/profile-request");
    }
  };

  useEffect(() => {
    setFilteredFullProfiles(fullProfileReceived);
    setFilteredBasicProfiles(basicProfileReceived);
  }, [fullProfileReceived, basicProfileReceived]);

  useEffect(() => {
    handleNewResult();
  }, [searchVal, propertyFilter]);

  const searchTenant = async (e) => {
    //console.log(e.target.value);

    let q = e.target.value;
    setSearchVal(q);
  };

  const handleFilterChange = (e) => {
    const val = e.target.value;
    setPropertyFilter(val);
  };

  const handleNewResult = () => {
    var all_result = [...fullProfileReceived];
    console.log("all_result", all_result);
    let queryText = searchVal.toLowerCase();

    if (haveValue(queryText)) {
      all_result = all_result.filter((item) => {
        let fname = `${item?.tenantid?.fname.toLowerCase()} ${item?.tenantid?.lname.toLowerCase()}`;
        return fname.includes(queryText);
      });
    }

    if (haveValue(propertyFilter) && propertyFilter != "all") {
      const all_prop_ids = all_result.map((item) =>
        item?.propertyid.map((item2) => item2?._id)
      );
      console.log(all_prop_ids);
      all_result = all_result.filter((item, key) =>
        all_prop_ids[key].includes(propertyFilter)
      );
    }

    setFilteredFullProfiles(all_result);
  };

  const handlecopyUrl = () => {
    copy(
      `https://${process.env.REACT_APP_URL_NAME}/home?requestProfileLink=${user?._id}`
    );
    toast.success(_t("linkCopied"));
  };

  const svgRef = useRef();

  const handleDownloadQR = useCallback(() => {
    const svg = svgRef.current.innerHTML;
    const blob = new Blob([svg], { type: "image/svg+xml" });
    downloadBlob(blob, `myimage.svg`);
  }, []);

  const calculateScore = (userDetails) => {
    var result = 0;
    if (checkIeDetails(userDetails)) {
      var totalScore =
        (userDetails?.paymentScores?.creditScore?.value || 0) / 2;

      if (userDetails?.is_university) {
        if (totalScore >= 600) {
          totalScore += 200;
        } else {
          totalScore += 400;
        }
      }
      if (totalScore > 850) {
        totalScore = 850;
      }
      result = totalScore;
    } else {
      if (haveValue(userDetails?.paymentScores?.creditScore?.value)) {
        result = userDetails?.paymentScores?.creditScore?.value;
      }
    }

    return result;
  };

  const checkSalary = (user) => {
    if (haveValue(user?.gurantor_id)) {
      if (user?.gurantor_id?.algoanIncome) {
        let monthlyIncome = parseInt(
          user?.gurantor_id?.algoanIncome?.monthlyAmount
        );
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user?.gurantor_id?.current_annual_income)
            ? user?.gurantor_id?.current_annual_income
            : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        return {
          matched: salaryMatch,
          salary: recorded_annual_salary,
        };
      }
    } else {
      if (user?.algoanIncome) {
        let monthlyIncome = parseInt(user?.algoanIncome?.monthlyAmount);
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user.current_annual_income) ? user.current_annual_income : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        return {
          matched: salaryMatch,
          salary: recorded_annual_salary,
        };
      }
    }
  };

  const calculateRentAmount = (e, val) => {
    setRentPercentage(val);
    setIsChanged(true);
  };

  const eachRentAmount = (fullProfile) => {
    if (
      haveValue(fullProfile?.rent_from_income) &&
      haveValue(fullProfile?.rent_percentage)
    ) {
      if (fullProfile?.rent_from_income == "current_annual_income") {
        return (
          convertCurrencyFormat(
            Math.floor(
              ((fullProfile?.tenantid?.current_annual_income || 0) *
                fullProfile?.rent_percentage) /
                1200
            )
          ) || 0
        );
      } else if (fullProfile?.rent_from_income == "itr_amount") {
        return (
          convertCurrencyFormat(
            Math.floor(
              ((fullProfile?.tenantid?.itr_amount || 0) *
                fullProfile?.rent_percentage) /
                1200
            )
          ) || 0
        );
      } else if (fullProfile?.rent_from_income == "recordedSalary") {
        const recordedSal = checkSalary(fullProfile?.tenantid);
        return (
          convertCurrencyFormat(
            Math.floor(
              ((recordedSal?.salary || 0) * fullProfile?.rent_percentage) / 1200
            )
          ) || 0
        );
      }
    } else {
      if (rentDoc == "current_annual_income") {
        return (
          convertCurrencyFormat(
            Math.floor(
              ((fullProfile?.tenantid?.current_annual_income || 0) *
                rentPercentage) /
                1200
            )
          ) || 0
        );
      } else if (rentDoc == "itr_amount") {
        return (
          convertCurrencyFormat(
            Math.floor(
              ((fullProfile?.tenantid?.itr_amount || 0) * rentPercentage) / 1200
            )
          ) || 0
        );
      } else if (rentDoc == "recordedSalary") {
        const recordedSal = checkSalary(fullProfile?.tenantid);
        return (
          convertCurrencyFormat(
            Math.floor(((recordedSal?.salary || 0) * rentPercentage) / 1200)
          ) || 0
        );
      }
    }
  };

  const handleChangeRent = () => {
    const temp_data = { rent_from: rentDoc, rent_percentage: rentPercentage };
    updateUserInfo(temp_data)
      .then((res) => {
        let usertype = localStorage.getItem("usertype");
        loadUserTenant(usertype);
      })
      .catch((err) => {
        console.log("Error while updating user info");
      });
    setIsChanged(false);
  };

  console.log(rentDoc, rentPercentage);

  const filterChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  if (loading) return <Spinner />;

  return (
    <>
      <div className="tabs-box-pattern tabs-box-pattern-2">
        <div className="tabs-item d-block">
          <div className="row">
            <div className="profile-page marless mobile-max-less">
              <div className="Search-bar">
                <input
                  type="text"
                  className="custom-input mb-0"
                  onChange={searchTenant}
                  value={searchVal}
                  placeholder={_t("searchTenants")}
                />
                <a href="#">
                  <img src={searchicon} alt="" />
                </a>
              </div>
              <div className="shareQr">
                <a
                  className="btn btngreen"
                  href={undefined}
                  onClick={(e) => setShowModal(true)}
                >
                  {_t("requestProfile")}
                </a>
              </div>
              <div>
                <select
                  className="form-control row50"
                  name="link_property"
                  id="link_property"
                  onChange={handleFilterChange}
                  value={propertyFilter}
                >
                  <option value="all">{_t("allProperties")}</option>
                  {properties.map((prop, index) => (
                    <option value={prop._id}>
                      {prop.street} - {prop.street_number}, {prop.floor_number}-
                      {prop.flat_number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="p-0">
              <div className="row max-rent-div mobile-max-rent-heading">
                <h4 className="">{_t("maxRent")}</h4>
                <div className="col-sm-6 row100">
                  <p className="percentage_salary_p">{_t("incomeFrom")}</p>
                  <select
                    name="rent_from_income_select"
                    id="rent_from_income_select"
                    className="custom-input income_select"
                    onChange={(e) => setRentDoc(e.target.value)}
                    value={rentDoc}
                    style={{ width: "100%" }}
                  >
                    <option value="current_annual_income">
                      {" "}
                      {_t("annualIncome")}{" "}
                    </option>
                    <option value="itr_amount">
                      {_t("verifiedIncomeFromIRPF")}
                    </option>
                    <option value="recordedSalary">
                      {_t("verifiedAnnualIncome")}
                    </option>
                  </select>
                </div>
                <div className="col-sm-6">
                  <p className="percentage_salary_p">
                    {_t("percentageOfRentFromSalary")}
                  </p>
                  <div className="d-flex justify-content-between">
                    <div className="col-sm-12">
                      <RentSlider
                        className="rent-slider"
                        defaultValue={rentPercentage}
                        aria-label="Default"
                        onChangeCommitted={calculateRentAmount}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => `${value}%`}
                        marks={marks}
                        min={1}
                        max={100}
                      />
                    </div>
                    {/* <div className="col-sm-3 max_rent_div d-flex row">
                      <p className="max_rent_title col-sm-12">
                        {"Max Rent Amount"}
                      </p>
                      <p className="col-sm-12 max_rent_val">
                        €
                        {haveValue(rentValFrom)
                          ? convertCurrencyFormat(
                              (rentValFrom * rentPercentage) / 1200
                            ) || 0
                          : 0}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end p-0 mobileprofile-select">
              {/* <div className="form-group">
                <select
                  className="form-control share-filter"
                  name="createdAt"
                  value={filters["createdAt"]}
                  onChange={filterChangeHandler}
                >
                  <option value="asc">{_t('Asc')}</option>
                  <option value="desc">{_t('Desc')}</option>
                </select>
              </div> */}
              <div className="form-group">
                {/* <label>{_t("Profile Completion")}:</label> */}
                <select
                  className="form-control share-filter"
                  name="myScore"
                  value={filters["myScore"]}
                  onChange={filterChangeHandler}
                >
                  <option value="">{_t("select")}</option>
                  <option value="0">0%</option>
                  <option value="25">25%</option>
                  <option value="50">50%</option>
                  <option value="75">75%</option>
                  <option value="100">100%</option>
                </select>
              </div>
            </div>
            <div className="tabs-profile">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="edit-profile">
                    <div className="Notifications-row">
                      {filteredFullProfiles.length == 0 && (
                        <>
                          {" "}
                          <p style={{ textAlign: "center" }}>
                            {" "}
                            {_t("youHaveNotReceivedAnyFullProfilesYet")}
                          </p>
                        </>
                      )}

                      {filteredFullProfiles &&
                        filteredFullProfiles.map((fullProfile, index) => (
                          <Link
                            to={`/profile-property?sharedprofileid=${fullProfile?._id}`}
                            className="back-part-wapper profile-notification-box"
                          >
                            <div
                              className="Notifications-wapper opacity-wapper px-0 setgridbarnotify row"
                              key={index}
                            >
                              <div className="leftyshare sharingcardblock col-md-5">
                                <h4>
                                  {fullProfile?.tenantid?.fname}{" "}
                                  {fullProfile?.tenantid?.lname}
                                  <span className="max-rent-span">
                                    {_t("maxRent")}:{" "}
                                    {eachRentAmount(fullProfile)}
                                    {haveValue(fullProfile?.rent_from_income) &&
                                    haveValue(fullProfile?.rent_percentage)
                                      ? ` [${fullProfile?.rent_percentage}%]`
                                      : ""}
                                  </span>
                                </h4>
                                <p>
                                  {_t("skor")}:{" "}
                                  {haveValue(
                                    fullProfile?.tenantid?.gurantor_id
                                      ?.paymentScores
                                  )
                                    ? fullProfile?.tenantid?.gurantor_id
                                        ?.paymentScores?.creditScore?.value
                                    : calculateScore(fullProfile?.tenantid)}
                                </p>
                                <p>
                                  {_t("assignedProperties")}:{" "}
                                  {fullProfile?.propertyid.length}
                                </p>
                              </div>
                              <div className="col-md-3">
                                <div className="position-relative steps-progress-bar">
                                  <div class="circle">
                                    <div
                                      class="mask full"
                                      style={{
                                        transform: `rotate(${
                                          (fullProfile?.tenantid?.myScore ||
                                            0) * 1.8
                                        }deg)`,
                                      }}
                                    >
                                      <div
                                        class="fill"
                                        style={{
                                          transform: `rotate(${
                                            (fullProfile?.tenantid?.myScore ||
                                              0) * 1.8
                                          }deg)`,
                                        }}
                                      ></div>
                                    </div>
                                    <div class="mask half">
                                      <div
                                        class="fill"
                                        style={{
                                          transform: `rotate(${
                                            (fullProfile?.tenantid?.myScore ||
                                              0) * 1.8
                                          }deg)`,
                                        }}
                                      ></div>
                                    </div>
                                    <div class="inside-circle">
                                      {" "}
                                      {fullProfile?.tenantid?.myScore ||
                                        0}%{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="rytyshare col-md-4">
                                <div className="d-flex justify-content-center">
                                  <img src={nexticon} alt="" style={{}} />
                                </div>
                                <h6 className="mb-0">
                                  {calculateNowTime(fullProfile?.createdAt)}
                                </h6>
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={(e) => setShowModal(false)}
        centered
        className="newmodalui max-w-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("yourProfileQR")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="qr-code-div" ref={svgRef}>
            <QRCode
              id="qr_code_image"
              value={`https://${process.env.REACT_APP_URL_NAME}/home?requestProfileLink=${user?._id}`}
            />
          </div>
          <div className="qr-link-copi">
            <input
              type="text"
              disabled
              value={`https://${process.env.REACT_APP_URL_NAME}/home?requestProfileLink=${user?._id}`}
            />
            <button type="button" onClick={handlecopyUrl}>
              <FaRegCopy />
              {_t("copyURL")}
            </button>
          </div>
          <div className="qr-code-download">
            <a href={undefined} onClick={handleDownloadQR}>
              <IoMdDownload />
              {_t("downloadNow")}
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileRequest;
