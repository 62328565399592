import { Card } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";
import { truncateString } from "../../helper/common";
import { VscEye } from "react-icons/vsc";

const DocCard = (props) => {
  const {
    doc_type = null,
    file_data: eachData,
    allowReUpload = true,
    handleUploadFile = () => {
      console.log("COMING HERE");
    },
  } = props;
  const { _t } = useTranslator();
  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  return (
    <>
      <div className="col-md-4">
        <Card className="mobile-tenant-box">
          <div className="videoframe">
            <iframe
              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
              scrolling="no"
            />
          </div>
          <Card.Body>
            <div className="listing-head">
              <div className="head-block">
                <div className="leftbartext" style={{ maxWidth: "100%" }}>
                  <div className="tenant-card-top">
                    <h5 style={{ paddingTop: "0px" }}>
                      {_t(eachData?.type, "fixed")}
                    </h5>
                    <VscEye
                      onClick={(e) =>
                        handlePreviewDocument(
                          `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="tenant-card-footer">
                    <p style={{ overflow: "hidden" }} className="tenant-card-p">
                      {truncateString(
                        eachData?.file_name.split("-").slice(1).join("-"),
                        60
                      )}
                    </p>
                    {allowReUpload && (
                      <label>
                        <input
                          accept="application/pdf, image/jpeg, image/png, image/jpg"
                          multiple={false}
                          data-val={doc_type}
                          type="file"
                          onChange={handleUploadFile}
                          className="d-none"
                        />
                        <p className="dropbtn underline">
                          <img src="/images/reUpload.svg" alt="" />
                          {_t("reUpload")}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
          {eachData?.from == "clave" ? (
            <label className="doc-uploaded-from">{_t("clavePin")}</label>
          ) : (
            <label className="doc-uploaded-from">{_t("uploaded")}</label>
          )}
        </Card>
      </div>
    </>
  );
};

export default DocCard;
