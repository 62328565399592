import React, { useContext, useEffect } from "react";
import { CiBank } from "react-icons/ci";
import { useState } from "react";
import { BsBank2 } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import useTranslator from "../../hooks/useTranslator";
import { toast } from "react-toastify";
import { haveValue, getBaseUrl } from "../../helper/common";
import AuthContext from "../../context/auth/AuthContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { sendUniversalMail } from "../../services/sharedService";
import AlreadyGuarantorOf from "../AlreadyGuarantorOf";
import LoaderBank from "../LoaderBank";
import ScoreWaitModal from "../ScoreWaitModal";
import SomeoneGuarantor from "../SomeoneGuarantor";
import TenantContext from "../../context/tenant/TenantContext";
import axiosInstance from "../../services/authWrapper";

const BankingProcess = (props) => {
  const { setloader1 } = props;
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const {
    user,
    loadUser,
    setLoading,
    setBankLoading,
    setAlgoanLoading,
    loadUserTenant,
  } = authContext;
  const { skipVerification } = tenantContext;
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [showPendingScreen, setShowPendingScreen] = useState(false);
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);
  const [gurantorPresent, setGurantorPresent] = useState(false);
  const [waitBank, setWaitBank] = useState(false);

  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const error = queryParameters.get("error");
  const error_message = queryParameters.get("error_reason");
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {
    if (error) {
      toast.error(_t("bankDetailsCannotBeFetched"));
    }
    if (account_verification_report_id && transaction_report_id) {
      // snackbar.showMessage(_t("Connected to Bank"));
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("userNotAuthorized"));
      } else {
        console.log("Calculate Score============>");
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    console.log("payment score calculations");
    // setBankDiv("kyc-loading");
    await clearPaymentData(userid);
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
  };

  const clearPaymentData = async (tenantid) => {
    console.log("Clearing Payment Data ===========>> ");
    return new Promise((resolve) => {
      axiosInstance
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          console.log(res);
          console.log("previous data cleared");
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    setBankLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        console.log(serverResponse);
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
            getAnalysisDetails();
            setAlgoanLoading(true);
            setShowPendingScreen(true);
            setBankLoading(false);
          } else {
            toast.error(_t("skorCanNotBeCalculated"));
            setBankLoading(false);
          }
        }
        if (serverResponse.status === "success") {
          toast.success(_t("bankConnectedSuccessfully"));
          let usertype = localStorage.getItem("usertype");
          loadUser(usertype);
          // setBankDiv("kyc-loaded");
          setBankLoading(false);
        }
      })
      .catch((err) => {
        let err_res = err;
        setBankLoading(false);
        console.log(err_res);
      });
  };

  const getAnalysisDetails = async () => {
    let userid = localStorage.getItem("saveduserid");
    // setLoading(true);
    let myInterval = setInterval(async () => {
      await axiosInstance
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          setAlgoanLoading(false);
          clearInterval(myInterval);
          const userType = localStorage.getItem("usertype");
          loadUser(userType);
        })
        .catch((err) => {
          setAlgoanLoading(false);
          toast.error(_t("skorCanNotBeCalculated"));
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };

  const [Bankshow, BanksetShow] = useState(false);
  const BankhandleClose = () => BanksetShow(false);
  const bankhandleShow = () => BanksetShow(true);
  const [show, setShow] = useState(false);

  const [countryCodeError, setCountryCodeError] = useState("");

  const [tink_countryCode, setTink_countryCode] = useState("");

  const [showGurantor, setShowGurantor] = useState(false);
  const [showSkipUpload, setShowSkipUpload] = useState(false);
  const [guarantorData, setGuarantorData] = useState({
    gurantor_name: "",
    gurantor_email: "",
    analysisCompleted: true,
  });

  const handleShowGurantor = () => setShowGurantor(true);
  const handleHideGurantor = () => {
    setShowGurantor(false);
    setGuarantorData({
      gurantor_name: "",
      gurantor_email: "",
      analysisCompleted: true,
    });
  };
  const handleHideSkip = () => {
    bankhandleShow();
    setShowSkipUpload(false);
  };

  const handleGurantorModal = () => {
    console.log("CLICKED");
    handleShowGurantor();
  };

  const handleSubmitGurantor = async (e) => {
    e.preventDefault();
    let userid = localStorage.getItem("saveduserid");
    setLoading(true);
    await axiosInstance
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/gurantor/${userid}`,
        guarantorData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setLoading(false);
          handleHideGurantor();
          setGurantorPresent(true);
          setAlreadyGurantorOf(true);
          // setGurantorPresent(val => {console.log(val);return !val;})
          // setAlreadyGurantorOf(val => {console.log(val);return !val;})
          console.log("ALREADY PRESENT PARVEJ");
        } else {
          loadUser("tenant");
          toast.success(_t("guarantorAdded"));
          handleHideGurantor();
          sendMail(guarantorData?.gurantor_email, "score");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error);
        setLoading(false);
        //toast.error("Kyc Report Not Fetched")
      });
    console.log("SUBMITTED");
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("emailSent"));
        console.log(res);
      })
      .catch((err) => {
        snackbar.showMessage(_t("errorSendingMail"), err?.message);
        console.log(err);
      });
  };

  const changeCountryCode = (e) => {
    setTink_countryCode(e.target.value);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError(_t("pleaseSelectCountryCode"));
      return;
    } else {
      setCountryCodeError("");
    }
    // let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fhome&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    //
    var lang = "en_US";
    if (localStorage.getItem("i18nextLng") == "es") {
      lang = "es_ES";
    }
    const baseUrl = getBaseUrl();
    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_URL_NAME}%2Fhome&market=${tink_countryCode}&locale=${lang}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    window.open(tinkUrlLiveFront, "_self");
  };
  const handleSkipPart = async () => {
    setLoading(true);
    try {
      await skipVerification(localStorage.getItem("saveduserid"));
      await loadUserTenant("tenant");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {showPendingScreen ? (
        <div className="vreify-box">
          <div className="verify-detail">
            <div className="detailin">
              <h4>{_t("generatingScoreItMightTakeTime")}</h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="vreify-box">
          <div className="verify-detail">
            <CiBank />
            <div className="detailin">
              <h4>{_t("pleaseVerifyYourFunds")}</h4>
              <p>{_t("thisWillHelpProveYouAreFinanciallyReliable")}</p>
            </div>
          </div>
          <div className="verify-btn">
            {props?.algoanStatus != "pending" && (
              <button
                className="btn btnorange w-100"
                onClick={bankhandleShow}
                type="button"
              >
                {_t("connectBank")}
              </button>
            )}
            {props?.algoanStatus == "pending" && (
              <div className="status_bar">
                <h5>{_t("connectionInProgressItMayTakeFewMinutes")}</h5>
              </div>
            )}
          </div>
          {haveValue(user?.gurantor_for) &&
            haveValue(user?.gurantor_for?._id) ? null : (
            <div className="Guarantorbar">
              <div className="divider">{_t("oR")}</div>
              <button className="btn btnoutline" onClick={handleGurantorModal}>
                {" "}
                {_t("addGuarantor")}
              </button>
            </div>
          )}
          <h3 className="setNoInfoColor">
            <a href={undefined} onClick={(e) => setShowSkipUpload(true)}>
              {_t("skipToUploadDocuments")}
            </a>
          </h3>
        </div>
      )}
      <Modal
        show={Bankshow}
        onHide={BankhandleClose}
        centered
        className="newmodalui connectbankmodal"
        size="lg"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>{_t("Connect Bank")}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div className="row">
            <div className="col-sm-6 p-2 bankimagediv">
              <p>
                {_t(
                  "youWillNowBeRedirectedToConnectYourBankAccountThroughOurPartnerTinkPoweredByVisa"
                )}
              </p>
              <p>
                {_t(
                  "itsImportantForYouToKnowThatWeDoNotHaveDirectAccessToYourBankLoginDetailsThisProcessIsDesignedToMaintainYourCredentialsEncriptedFromSkorOrAnyOtherThirdParty"
                )}
              </p>
              <img src="../images/connect_bank.svg" alt="bank_logo" />
              <p>
                {_t(
                  "weAssureYouOfTheUtmostPrivacyAndSecurityOfYourFinancialData"
                )}
              </p>
            </div>
            <div className="col-sm-6 p-2 connectbankdiv">
              <h3>{_t("connectBank")}</h3>
              <Form className="loginform">
                <div className="auth-form ">
                  <div className="forminput row100">
                    <label className="forminput">{_t("country")}</label>
                    <select
                      className="form-control"
                      onChange={changeCountryCode}
                    >
                      <option value="">{_t("selectYourCountry")}</option>
                      <option value="BE">{_t("belgium")}</option>
                      <option value="DE">{_t("germany")}</option>
                      <option value="DK">{_t("denmark")}</option>
                      <option value="EE">{_t("estonia")}</option>
                      <option value="ES">{_t("spain")}</option>
                      <option value="FI">{_t("finland")}</option>
                      <option value="FR">{_t("france")}</option>
                      <option value="GB">{_t("unitedKingdom")}</option>
                      <option value="IT">{_t("italy")}</option>
                      <option value="LT">{_t("lithuania")}</option>
                      <option value="LV">{_t("latvia")}</option>
                      <option value="NL">{_t("netherlands")}</option>
                      <option value="NO">{_t("norway")}</option>
                      <option value="PL">{_t("poland")}</option>
                      <option value="PT">{_t("portugal")}</option>
                      <option value="SE">{_t("sweden")}</option>
                    </select>
                  </div>
                  <div className="forminput row100">
                    <label className="forminput">{_t("connectBank")}</label>

                    <div className="bankname" onClick={connectToBank}>
                      <BsBank2 />
                      {_t("connectBank")}
                    </div>
                    <span className="addRedColor">{countryCodeError}</span>
                  </div>
                </div>
              </Form>
              <div className="bank-btm">
                {_t("shouldYouHaveAnyConcernsOrQuestionsPlease")}
                <a
                  href={undefined}
                  onClick={(e) => navigate("/bank")}
                  className="btnlink"
                >
                  {_t("readHere")}
                </a>
                {_t("orContactUsAt")}
                <a href="mailTo:info@skortorent.com" className="btnlink">
                  info@skortorent.com.
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showGurantor}
        onHide={handleHideGurantor}
        centered
        className="newmodalui"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("addGuarantor")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitGurantor}>
            <div className="auth-form">
              <div className="forminput row100">
                <label className="forminput">{_t("nameOfGuarantor")}</label>
                <input
                  type="text"
                  name="gurantor_name"
                  placeholder={_t("nameOfGuarantor")}
                  onChange={(e) =>
                    setGuarantorData((prevData) => ({
                      ...prevData,
                      gurantor_name: e.target.value,
                    }))
                  }
                  value={guarantorData.gurantor_name}
                />
              </div>
              <div className="forminput row100">
                <label className="forminput">{_t("emailOfGuarantor")}</label>
                <input
                  type="text"
                  name="gurantor_email"
                  placeholder={_t("emailOfGuarantor")}
                  onChange={(e) =>
                    setGuarantorData((prevData) => ({
                      ...prevData,
                      gurantor_email: e.target.value.toLowerCase(),
                    }))
                  }
                  value={guarantorData.gurantor_email}
                />
              </div>
              <div className="btngroup row100 gridbar">
                <button
                  className="btn btnoutline"
                  type="button"
                  onClick={handleHideGurantor}
                >
                  {_t("cancel")}
                </button>
                <button
                  className="btn btnorange"
                  type="submit"
                  disabled={
                    haveValue(guarantorData.gurantor_name) &&
                      haveValue(guarantorData.gurantor_email)
                      ? false
                      : true
                  }
                >
                  {_t("submit")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSkipUpload}
        onHide={handleHideSkip}
        centered
        className="newmodalui"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("areYouSureYouWantToSkip")}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="auth-form">
            <p className="skip_modal">
              {_t("digitalFundVerificationHelpsYou")}
            </p>
            <p className="skip_modal">
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L11 6.5L0 13V0Z" fill="#253837" />
              </svg>
              {_t("enhanceLandlordApprovalAndSetsYouApartFromOtherApplicants")}.
            </p>
            <p className="skip_modal">
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L11 6.5L0 13V0Z" fill="#253837" />
              </svg>
              {_t("demonstrateFinancialStabilityToReduceSecurityDeposits")}.
            </p>
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={handleSkipPart}
              >
                {_t("continue")}
              </button>
              <button
                className="btn btnorange"
                type="button"
                onClick={handleHideSkip}
              >
                {_t("verifyYourAccount")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {alreadyGurantorOf && gurantorPresent == true && (
        <SomeoneGuarantor
          alreadyGurantorOf={alreadyGurantorOf}
          setAlreadyGurantorOf={setAlreadyGurantorOf}
          gurantorPresent={gurantorPresent}
          setGurantorPresent={setGurantorPresent}
        />
      )}
    </>
  );
};

export default BankingProcess;
