import Button from "react-bootstrap/Button";
import { BsLinkedin } from "react-icons/bs";
import useTranslator from "../../../hooks/useTranslator";
import { getBaseUrl } from "../../../helper/common";
import { useContext } from "react";
import AuthContext from "../../../context/auth/AuthContext";

const SelectUserEmailScreen = ({
  userLogin,
  emailSubmitHandler,
  onChangeHandler,
  disabled = false,
}) => {
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const { generateLinkedinURL } = authContext;

  const linkedinSignup = async () => {
    const baseUrl = getBaseUrl();
    // let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=http://localhost:3000/register`;
    const response = await generateLinkedinURL();

    let live_url = response.linkedinUrl;

    // window.open(url,'_self');
    window.open(live_url, "_self");
  };

  return (
    <form onSubmit={emailSubmitHandler}>
      <div className="linkedbtn">
        <Button
          className="btn-fill  dakstop-linkedinbtn"
          onClick={linkedinSignup}
        >
          <BsLinkedin />
          {_t("continueWithLinkedin")}
        </Button>
      </div>

      <div className="or-bar">
        <p>{_t("or")}</p>
      </div>

      <div className="auth-form d-blockform">
        <div className="forminput">
          <label className="forminput">{_t("email")}</label>
          <input
            type="text"
            className="form-control user-auth"
            name="email"
            value={userLogin?.email ?? ""}
            placeholder={_t("email")}
            onChange={onChangeHandler}
            disabled={disabled}
          />
        </div>
        <div className="btngroup">
          <button type="submit" className="btn btnorange" disabled={disabled}>
            {" "}
            {_t("Continue")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SelectUserEmailScreen;
