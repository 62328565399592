import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthContext from "../../../context/auth/AuthContext";
import TenantContext from "../../../context/tenant/TenantContext";
import ReactStars from "react-rating-stars-component";

import { AiOutlineInfoCircle } from "react-icons/ai";

import { useSnackbar } from "material-ui-snackbar-provider";

import searchicon from "../../../assets/img/s.png";
import locationicon from "../../../assets/img/location.png";
import nexticon from "../../../assets/img/back.png";
import checkicon from "../../../assets/img/check.png";
import backicon from "../../../assets/img/back.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import useTranslator from "../../../hooks/useTranslator";
import axiosInstance from "../../../services/authWrapper";

const RentedProperty = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const authContext = useContext(AuthContext);
  const { user, userType, loadMyNotifications } = authContext;

  const tenantContext = useContext(TenantContext);
  const {
    rentedProperties,
    myRentedProperties,
    myPreviouslyRentedProperties,
    previouslyRentedProperties,
    endLease,
  } = tenantContext;

  const [rating, setRating] = useState({
    stars: 0,
    feedback_text: "",
  });

  const [selected_propertyid, setSelectedPropertyid] = useState("");

  useEffect(() => {
    let userid = localStorage.getItem("saveduserid");
    myRentedProperties(userid);
    loadMyNotifications(userid, userType);
    myPreviouslyRentedProperties(userid);
  }, []);

  const endPropertyLease = (propertyid) => {
    endLease(propertyid, user?._id);
  };

  const getPropertyStartDate = (prev_tenants) => {
    for (let prev_tenant of prev_tenants) {
      if (prev_tenant.tenantid == user?._id) {
        let d = new Date(prev_tenant.lease_started_on);
        return d.toISOString().substring(0, 10);
      }
    }
  };

  const getPropertyEndDate = (prev_tenants) => {
    //console.log(prev_tenants);

    for (let i = 0; i < prev_tenants.length; i++) {
      //console.log(prev_tenants[i]);
      if (prev_tenants[i]?.tenantid == user?._id) {
        //console.log("matched",prev_tenants[i]);
        let d = new Date(prev_tenants[i]?.lease_ended_on);
        return d.toISOString().substring(0, 10);
      }
    }
  };

  const calculateDate = (prev_tenants) => {
    for (let prev_tenant of prev_tenants) {
      if (prev_tenant.tenantid == user?._id) {
        let leaseEnd = moment(prev_tenant.lease_ended_on);
        let leaseStart = moment(prev_tenant.lease_started_on);
        let timeCalculated = leaseEnd - leaseStart;
        //return (Math.ceil(timeCalculated / (3600 * 24000 * 30))); //60*60*24*1000  in days
        return `${leaseEnd.diff(leaseStart, "months")} month(s)`;
      }
    }
  };

  const formattedDate = (rent_date) => {
    let leaseStart = new Date(rent_date);
    return leaseStart.toISOString().substring(0, 10);
  };

  const calculateDateNow = (rent_date) => {
    let leaseStart = moment(rent_date);
    let leaseCurr = moment(Date.now());
    console.log(`${leaseCurr.diff(leaseStart, "months")} month(s)`);
    let timeCalculated = leaseCurr - leaseStart;
    //return (Math.ceil(timeCalculated / (3600 * 24000 * 30))); //60*60*24*1000  in days
    return `${leaseCurr.diff(leaseStart, "months")} month(s)`;
  };

  //full feedback sending flow
  const ratingChanged = (newRating) => {
    console.log(newRating);
    setRating({ ...rating, stars: newRating });
  };

  const feedbackChange = (e) => {
    setRating({ ...rating, [e.target.name]: e.target.value });
  };

  let userid = localStorage.getItem("saveduserid");
  let feedbackBody = {
    propertyid: "",
    tenantid: userid,
    feedback_body: {},
  };

  const setFeedbackInfo = (selected_property, landlordid) => {
    setSelectedPropertyid(selected_property);
  };

  const submitFeedback = (e) => {
    e.preventDefault();
    feedbackBody.propertyid = selected_propertyid;
    feedbackBody.feedback_body = rating;
    console.log("final feedback request sent to server", feedbackBody);
    if (feedbackBody.propertyid == "") {
      toast.warning(_t("pleaseSelectProperty"));
    } else {
      sendFeedback();
    }
  };

  const sendFeedback = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/tenants/create-feedback`;
    await axiosInstance
      .post(url, feedbackBody, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        console.log(res.data);
        snackbar.showMessage(_t("feedbackRecorded"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <div className='margin-div'></div> */}

      <div className="tabs-box-pattern">
        <div className="tabs-item d-block px-0">
          <div className="row m-0">
            <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
              <div>
                {/* <Link to='/home'><img src={backicon} alt="backbtn" /></Link> */}
              </div>

              <h3>{_t("rentedProperty")}</h3>

              <div></div>
            </div>
          </div>

          <div className="Notifications-row rented-property">
            {
              //active rented properties

              rentedProperties &&
                rentedProperties.map((rentedProperty, index) => (
                  <>
                    <div
                      className="orange-wappper bg-orange d-block"
                      key={"rented" + rentedProperty?._id}
                    >
                      <div className="location-w">
                        <p className="infotext" style={{ textAlign: "left" }}>
                          <a href="#">
                            <img src={locationicon} alt="" />
                          </a>{" "}
                          {rentedProperty?.address},{rentedProperty?.province}
                          <AiOutlineInfoCircle className="infoicon" />
                        </p>
                      </div>
                      <div className="d-flex wappper-location">
                        <div className="or-wapper">
                          <h5>
                            {rentedProperty?.landlordtype || "Agent"}:{" "}
                            <span>
                              {rentedProperty?.landlord?.fname_companyName}
                              {rentedProperty?.landlord?.lname}
                            </span>
                          </h5>
                          <h5>
                            {formattedDate(
                              rentedProperty?.tenancy_status?.rent_started
                            )}{" "}
                            - {_t("present")} -{" "}
                            {calculateDateNow(
                              rentedProperty?.tenancy_status?.rent_started
                            )}
                          </h5>
                        </div>
                        <div className="end-lease">
                          <Link
                            onClick={() =>
                              endPropertyLease(rentedProperty?._id)
                            }
                          >
                            {_t("endLease")}?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ))
            }

            {rentedProperties.length == 0 &&
              previouslyRentedProperties.length == 0 && (
                <>
                  {" "}
                  <p style={{ textAlign: "center" }}>
                    {" "}
                    {_t("youHaveNotRentedAnyPropertiesYet")}
                  </p>
                </>
              )}

            {
              //previous rented properties

              previouslyRentedProperties &&
                previouslyRentedProperties.map((prevRentedProperty, index) => (
                  <>
                    <div
                      className="Notifications-wapper opacity-wapper "
                      key={"prevrented" + prevRentedProperty?._id}
                    >
                      <div>
                        <p className="infotext">
                          <a href="#">
                            <img
                              className="location-img"
                              src={locationicon}
                              alt=""
                            />
                          </a>{" "}
                          {prevRentedProperty?.address},
                          {prevRentedProperty?.province}
                          <AiOutlineInfoCircle className="infoicon" />
                        </p>

                        <h6>
                          {getPropertyStartDate(
                            prevRentedProperty?.previous_tenants
                          )}{" "}
                          -{" "}
                          {getPropertyEndDate(
                            prevRentedProperty?.previous_tenants
                          )}{" "}
                          -{" "}
                          {calculateDate(prevRentedProperty?.previous_tenants)}
                        </h6>
                      </div>

                      <div className="share-it-profile">
                        <Link
                          onClick={() =>
                            setFeedbackInfo(
                              prevRentedProperty?._id,
                              prevRentedProperty?.landlord
                            )
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          {_t("sendFeedback")}
                        </Link>
                      </div>
                    </div>
                  </>
                ))
            }

            {/*  Modal  */}
            <div
              className="modal fade"
              id="staticBackdrop"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content Feedback-header">
                  <div className="">
                    <div className="Feedback-back">
                      <h5>{_t("sendFeedback")} </h5>

                      <div className="feedback-star-div">
                        <ReactStars
                          className={"feedback-star"}
                          count={5}
                          onChange={ratingChanged}
                          size={40}
                          activeColor="#F59F18"
                        />
                      </div>
                      <textarea
                        id=""
                        placeholder="Type your feedback here........"
                        name="feedback_text"
                        onChange={feedbackChange}
                      ></textarea>
                      <button
                        type="button"
                        onClick={submitFeedback}
                        className="btn-fill2 w-100  mt-3"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        {_t("send")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentedProperty;
