import react, { Fragment, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Navbar from "../layouts/Navbar";
import Sidebar from "../layouts/Sidebar";
import AuthContext from "../context/auth/AuthContext";
import LandLordContext from "../context/landlord/LandLordContext";
import Loader from "../components/Loader";
import useTranslator from "../hooks/useTranslator";
import LoaderBank from "../components/LoaderBank";

const Homepage = () => {
  const { _t } = useTranslator();

  const authContext = useContext(AuthContext);
  const { loadUser, loading, setBankLoading, bankLoading } = authContext;
  const navigate = useNavigate();
  const landLordContext = useContext(LandLordContext);
  const { loadingLandlord, demoFunLandlord, setProperties, properties } =
    landLordContext;

  useEffect(() => {
    let token = localStorage.getItem("token");
    let usertype = localStorage.getItem("usertype");
    if (token && usertype) {
      loadUser(usertype);
      let sharedpath = localStorage.getItem("sharedurl");
      if (usertype === "tenant" && sharedpath) {
        window.location.href = sharedpath;
      }
      //console.log("HOME runs")
    } else {
      // navigate("/login");
      navigate("/auth");
    }
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div id="main-app-loader">
            <Loader />
          </div>
        </>
      ) : null}
      {bankLoading ? (
        <>
          <div id="main-app-loader">
            <LoaderBank />
          </div>
        </>
      ) : null}

      {/* <Container> */}
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12}>
          <Navbar />
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item md={0} lg={2}>
          <Sidebar />
        </Grid>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <div className="margin-div">
            <Outlet />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Homepage;
