import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { haveValue } from "../helper/common";
const RedirectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    // Extract the token from the query parameters
    const token = queryParams.get("token");
    const usertype = queryParams.get("usertype");
    const is_verified = queryParams.get("is_verified");
    const saveduserid = queryParams.get("saveduserid");
    const external_customer_id = queryParams.get("external_customer_id");
    const app_type = queryParams.get("app_type");
    const goToPage = queryParams.get("go_to_page");

    localStorage.setItem("token", token);
    localStorage.setItem("usertype", usertype);
    localStorage.setItem("is_verified", is_verified);
    localStorage.setItem("saveduserid", saveduserid);
    localStorage.setItem("external_customer_id", external_customer_id);
    localStorage.setItem("app_type", app_type);
    // localStorage.setItem("goToPage", goToPage);

    if (usertype === "tenant") {
      if (haveValue(goToPage)) {
        navigate(goToPage);
      } else {
        navigate("/tenant");
      }
    } else {
      navigate("/home");
    }
  }, [location]);
  return "RedirectPage";
};

export default RedirectPage;
