import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import constants from "../../../Utils/constants";
import {
  haveValue,
  truncateString,
  skipMaccUser,
} from "../../../helper/common";
import TenantContext from "../../../context/tenant/TenantContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { toast } from "react-toastify";
import { VscEye } from "react-icons/vsc";
import Loader from "../../../components/Spinner";
import DocCard from "../../../components/Tenant/DocCard";

const TenantProvideDocumentNew = () => {
  // const clave_docs = new Map();
  const { _t } = useTranslator();
  const queryParameters = new URLSearchParams(window.location.search);
  const modelType = queryParameters.get("type");
  const waitingId = queryParameters.get("waiting");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const {
    uploadFileData,
    fetchTenantDocs,
    updateTenant,
    initiateBankFlip,
    fetchWaitingData,
  } = tenantContext;
  const { user, loadUser } = authContext;
  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [tenantDocsData, setTenantDocsData] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isBankflip, setIsBankflip] = useState(false);
  const [currentStep, setCurrentStep] = useState(() => {
    return skipMaccUser(user?.user_type) ? 1 : 0;
  });
  const [revisit, setRevisit] = useState(false);
  const [fromClave, setFromClave] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [clave_docs, set_clave_docs] = useState(new Map());
  const [skipStep, setSkipStep] = useState(false);
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const [docFound, setDocFound] = useState(new Map());
  const [claveModels, setClaveModels] = useState({
    AEAT_100_RENTA: false,
    AEAT_130_IRPF_DIRECTA: false,
    AEAT_303_IVA_AUTOLIQUIDACION: false,
    SEG_SOCIAL_VIDA_LABORAL: false,
  });

  useEffect(() => {
    fetchFileData();
    if (haveValue(modelType) && modelType == "manual") {
      setCurrentStep(1);
      setFromClave(false);
    }
    if (haveValue(waitingId)) {
      setLocalLoading(true);
      const temp_interval = setInterval(() => {
        fetchWaitingData(waitingId)
          .then((res) => {
            if (res.status == constants.COMPLETED) {
              clearInterval(temp_interval);
              fetchFileData();
              setLocalLoading(false);
            }
          })
          .catch((err) => {
            setLocalLoading(false);
            clearInterval(temp_interval);
            console.log(err);
          });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (user?.docs_uploaded) {
      setRevisit(true);
    }
    if (user?.employment_status == constants.employed) {
      setClaveModels((prev) => ({
        ...prev,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
      }));
    }
    if (user?.employment_status == constants.self_employed) {
      setClaveModels((prev) => ({
        ...prev,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
        AEAT_130_IRPF_DIRECTA: true,
      }));
    }
    if (
      user?.employment_status == constants.self_employed &&
      user?.is_freelancer == true
    ) {
      setClaveModels((prev) => ({
        ...prev,
        AEAT_303_IVA_AUTOLIQUIDACION: true,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (tenantDocsData.length > 2) {
      setSliderSettings({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        nextArrow: <BsFillArrowRightSquareFill />,
        prevArrow: <BsFillArrowLeftSquareFill />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
    if (haveValue(tenantDocsData) && tenantDocsData.length > 1) {
      const temp_map = new Map();
      const type_map = new Map();
      tenantDocsData.map((docs) => {
        if (docs?.from == "clave") {
          console.log(docs?.type, "docs?.typedocs?.type");
          temp_map.set(docs?.type);
        }
        if (!type_map.has(docs?.type)) {
          type_map.set(docs?.type, docs);
        }
      });
      setDocFound(type_map);
      set_clave_docs(temp_map);
    }
  }, [tenantDocsData]);

  useEffect(() => {
    if (user) {
      let res = skipMaccUser(user?.user_type) ? true : false;
      if (res === true && currentStep === 0) {
        setCurrentStep(1);
      }
      setSkipStep(res);
    }
  }, [user]);

  const navigateHome = () => {
    navigate("/tenant");
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
    if (
      e.target.value == constants.income_tax_return ||
      e.target.value == constants.fractioned_tax_return ||
      e.target.value == constants.quarterly_vat ||
      e.target.value == constants.previous_working_life_certificate
    ) {
      // checkBankflip(e.target.value);
      setIsBankflip(true);
    } else {
      setIsBankflip(false);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const fetchFileData = async () => {
    console.log("fetchFileData called");
    let userid = localStorage.getItem("saveduserid");
    const res = await fetchTenantDocs(userid);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleSubmitDocuments = async () => {
    setCurrentStep(2);
  };

  const checkNextButton = () => {
    if (!haveValue(user?.onfido_kyc_success) || !user?.onfido_kyc_success) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/verifyId")}
        >
          {_t("continueToVerifyId")}
        </button>
      );
    } else if (
      (!haveValue(user?.have_gurantor) ||
        !user?.have_gurantor ||
        user?.have_gurantor == "false") &&
      (!haveValue(user?.kyc_success) ||
        !user?.kyc_success ||
        user?.kyc_success == "false")
    ) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/connectBank")}
        >
          {_t("continueToConnectBank")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const handleClaveChange = (e) => {
    const event = e.target;
    setClaveModels((prev) => ({ ...prev, [event.value]: event.checked }));
  };

  const checkBankflip = () => {
    if (
      !claveModels.AEAT_100_RENTA &&
      !claveModels.AEAT_130_IRPF_DIRECTA &&
      !claveModels.AEAT_303_IVA_AUTOLIQUIDACION &&
      !claveModels.SEG_SOCIAL_VIDA_LABORAL
    ) {
      toast.error(_t("selectAtLeastOneDocument"));
      return;
    }
    initiateBankFlip(claveModels)
      .then((res) => {
        window.location.replace(res.success_res.widgetLink);
        console.log(res, "BANK FLIP RESPONSE");
      })
      .catch((err) => {
        console.log(err);
        toast.error(_t("errorWhileInitiatingTheSignatureProcess"));
      });
  };

  const createOption = (certificateType) => {
    let element = "";
    let cloveHasDoc = clave_docs.has(certificateType);

    switch (certificateType) {
      case constants.previous_working_life_certificate:
        element = (
          <option
            value={constants.previous_working_life_certificate}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("workingLifeCertificate")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      case constants.income_tax_return:
        element = (
          <option
            value={constants.income_tax_return}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("lastAnnualTaxReportModel100")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      case constants.fractioned_tax_return:
        element = (
          <option
            value={constants.fractioned_tax_return}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("lastFractionatedTaxReportModel130")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      case constants.quarterly_vat:
        element = (
          <option
            value={constants.quarterly_vat}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("lastQuarterlyVATTaxForm303")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      default:
        element = "";
    }
    return element;
  };

  const handleFileUpload = async () => {
    try {
      setUploadLoading(true);
      await uploadFileData(fileData);

      setFileData(null);
      setFileType("");
      await fetchFileData();
      handleClose();
      navigate("?type=manual");
      setUploadLoading(false);
    } catch (err) {
      setUploadLoading(false);
      console.log(err);
    }
  };

  const handleInitiateClave = async (e) => {
    let temp_clave_models = {
      AEAT_100_RENTA: false,
      AEAT_130_IRPF_DIRECTA: false,
      AEAT_303_IVA_AUTOLIQUIDACION: false,
      SEG_SOCIAL_VIDA_LABORAL: false,
    };

    if (user?.employment_status == constants.employed) {
      temp_clave_models = {
        ...temp_clave_models,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
      };
    }
    if (user?.employment_status == constants.self_employed) {
      temp_clave_models = {
        ...temp_clave_models,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
        AEAT_130_IRPF_DIRECTA: true,
      };
    }
    if (
      user?.employment_status == constants.self_employed &&
      user?.is_freelancer == true
    ) {
      temp_clave_models = {
        ...temp_clave_models,
        AEAT_303_IVA_AUTOLIQUIDACION: true,
      };
    }

    console.log(temp_clave_models, "check models here");

    initiateBankFlip(temp_clave_models)
      .then((res) => {
        window.location.replace(res.success_res.widgetLink);
        console.log(res, "BANK FLIP RESPONSE");
      })
      .catch((err) => {
        console.log(err);
        toast.error(_t("errorWhileInitiatingTheSignatureProcess"));
      });
    console.log("INITIATED CLAVE PIN");
  };

  const handleUploadFile = async (e) => {
    try {
      setLocalLoading(true);
      const val_type = e.target.dataset.val;
      const files = e.target.files;
      let file = files[0];
      const formData = new FormData();
      formData.append("tenantDocs", file);
      formData.append("fileType", val_type);
      console.log(file, val_type);
      await uploadFileData(formData);
      await fetchFileData();
      setLocalLoading(false);
    } catch (err) {
      setLocalLoading(false);
      console.log(err, "error inside handleUploadFile");
    }
  };

  const handleChangeClavePinStatus = async (status) => {
    const sendData = {
      haveClavePin: status,
    };
    await updateTenant(sendData);
    await loadUser("tenant");
  };

  console.log(tenantDocsData, "check tenantDocsData");

  return (
    <>
      <div className="step-form-main-wrapper">
        <div className="d-flex step-absolute-header step-form-header">
          <Container className="p-0 text-center back-btn">
            <div className="">
              <a href={undefined} onClick={navigateHome}>
                <img src="/images/back-btn-white.svg" alt="" />
                {/* <span>{_t("Go Back To Home")} </span> */}
              </a>
            </div>
          </Container>
          <h3 className="step-form-name mobile-model-heading">
            <img src="/images/document-home-icon.svg" alt="" />
            <span>{_t("provideDocuments")}</span>
          </h3>
          <div className="">
            <button
              className="rounded-check summaryinfobar btn btngreen done-btn"
              id="viewDocumentBtn"
              onClick={navigateHome}
            >
              {_t("done")} <img src="/images/Tick.svg" alt="" />
            </button>
          </div>
        </div>
        <>
          <div className="row">
            {(user?.employment_status == constants.employed ||
              user?.employment_status == constants.self_employed) && (
              <div
                className={`col-md-3 calvepin-option-div tenant-left-box ${
                  haveValue(user?.haveClavePin) &&
                  !user?.haveClavePin &&
                  "tenant-light-box"
                } `}
              >
                {/* for light color= tenant-light-box */}
                <div className="tennat-left-box-top">
                  <img src="/images/clavePinLightGreen.svg" alt="" />
                  <h4>{_t("getFromClavePin")}</h4>
                  <p>
                    {_t(
                      "provideYourDocumentsToSkorViaClavePinWithEaseAndEfficiency"
                    )}
                  </p>
                  <h5>{_t("certificates")}</h5>
                  <ul>
                    {(user?.employment_status == constants.employed ||
                      user?.employment_status == constants.self_employed) && (
                      <>
                        <li>
                          <p>{_t("workingLifeCertificate")}</p>
                        </li>
                        <li>
                          <p>{_t("lastAnnualTaxReportModel100")}</p>
                        </li>
                      </>
                    )}

                    {user?.employment_status == constants.self_employed && (
                      <li>
                        <p>{_t("lastFractionatedTaxReportModel130")}</p>
                      </li>
                    )}
                    {user?.employment_status == constants.self_employed &&
                      user?.is_freelancer == true && (
                        <li>
                          <p>{_t("lastQuarterlyVATTaxForm303")}</p>
                        </li>
                      )}
                  </ul>
                </div>
                <div className="tenant-left-box-bottom">
                  {haveValue(user?.haveClavePin) && !user?.haveClavePin ? (
                    <button
                      className="btn btngreen light-box-btn btndarkgreen"
                      onClick={(e) => handleChangeClavePinStatus(true)}
                    >
                      {_t("enableClavePin")}{" "}
                      {/* <img src="/images/rightArrow.svg" alt="" className="mb-0" /> */}
                      <img
                        src="/images/lightGreenRightArrow.svg"
                        alt=""
                        className="mb-0"
                      />
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btngreen"
                        onClick={handleInitiateClave}
                      >
                        {_t("getFromClavePin")}{" "}
                        <img
                          src="/images/rightArrow.svg"
                          alt=""
                          className="mb-0"
                        />
                      </button>
                      <a
                        href={undefined}
                        onClick={(e) => handleChangeClavePinStatus(false)}
                        className="white-text underline tennat-eft-content-btn"
                      >
                        {_t("dontHaveClavePin")}
                      </a>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="col-md-9 tenant-right-box">
              {localLoading ? (
                <Loader waiting_text={_t("uploadingDocs")} />
              ) : (
                <div className="row">
                  {user?.employment_status == constants.student && (
                    <>
                      {docFound.has(constants.university_acceptance_letter) ? (
                        <DocCard
                          file_data={docFound.get(
                            constants.university_acceptance_letter
                          )}
                          doc_type={constants.university_acceptance_letter}
                          handleUploadFile={handleUploadFile}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className="doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4
                              data-val={constants.university_acceptance_letter}
                            >
                              {_t("universityAcceptanceLetter")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                type="file"
                                onChange={handleUploadFile}
                                data-val={
                                  constants.university_acceptance_letter
                                }
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}

                      {docFound.has(constants.ie_student) ? (
                        <DocCard
                          file_data={docFound.get(constants.ie_student)}
                          doc_type={constants.ie_student}
                          handleUploadFile={handleUploadFile}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className="doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.ie_student}>
                              {_t("studentRegistrationContract")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                type="file"
                                onChange={handleUploadFile}
                                data-val={constants.ie_student}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {user?.employment_status == constants.employed && (
                    <>
                      {docFound.has(constants.employment_contract) ? (
                        <DocCard
                          file_data={docFound.get(
                            constants.employment_contract
                          )}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.employment_contract}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className="doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.employment_contract}>
                              {_t("employmentContract")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.employment_contract}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {docFound.has(constants.firstMonthPayslip) ? (
                        <DocCard
                          file_data={docFound.get(constants.firstMonthPayslip)}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.firstMonthPayslip}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className="doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.firstMonthPayslip}>
                              {_t("firstMonthPayslip")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.firstMonthPayslip}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {docFound.has(constants.secondMonthPayslip) ? (
                        <DocCard
                          file_data={docFound.get(constants.secondMonthPayslip)}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.secondMonthPayslip}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.secondMonthPayslip}>
                              {_t("secondMonthPayslip")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.secondMonthPayslip}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {docFound.has(constants.thirdMonthPayslip) ? (
                        <DocCard
                          file_data={docFound.get(constants.thirdMonthPayslip)}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.thirdMonthPayslip}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className="doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.thirdMonthPayslip}>
                              {_t("thirdMonthPayslip")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.thirdMonthPayslip}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {docFound.has(constants.deduction_certificate) ? (
                        <DocCard
                          file_data={docFound.get(
                            constants.deduction_certificate
                          )}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.deduction_certificate}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.deduction_certificate}>
                              {_t("deductionCertificate")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.deduction_certificate}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {docFound.has(
                        constants.public_appointment_certificate
                      ) ? (
                        <DocCard
                          file_data={docFound.get(
                            constants.public_appointment_certificate
                          )}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.public_appointment_certificate}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4
                              data-val={
                                constants.public_appointment_certificate
                              }
                            >
                              {_t(
                                "publicAppointmentCertificateOnlyIfPublicEmployee"
                              )}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={
                                  constants.public_appointment_certificate
                                }
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer != true && (
                      <>
                        {docFound.has(constants.corporate_tax_return) ? (
                          <DocCard
                            file_data={docFound.get(
                              constants.corporate_tax_return
                            )}
                            handleUploadFile={handleUploadFile}
                            doc_type={constants.corporate_tax_return}
                          />
                        ) : (
                          <div className="col-md-4 ">
                            <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                              <img
                                src="/images/uploadDocPlaceholder.svg"
                                alt=""
                              />
                              <h4 data-val={constants.corporate_tax_return}>
                                {_t("lastYearsCorporateTaxReturn")}
                              </h4>
                              <p className="info-text-p">
                                {"_tuploadPdfPngJpgOrJpeg"}
                              </p>
                              <label>
                                <input
                                  accept="application/pdf, image/jpeg, image/png, image/jpg"
                                  multiple={false}
                                  data-val={constants.corporate_tax_return}
                                  type="file"
                                  onChange={handleUploadFile}
                                  className="d-none"
                                />
                                <span className="btn btngreen">
                                  {_t("upload")}{" "}
                                  <img src="/images/uploadIcon.svg" alt="" />
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                        {docFound.has(constants.corporate_balance_sheet) ? (
                          <DocCard
                            file_data={docFound.get(
                              constants.corporate_balance_sheet
                            )}
                            handleUploadFile={handleUploadFile}
                            doc_type={constants.corporate_balance_sheet}
                          />
                        ) : (
                          <div className="col-md-4 ">
                            <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                              <img
                                src="/images/uploadDocPlaceholder.svg"
                                alt=""
                              />
                              <h4 data-val={constants.corporate_balance_sheet}>
                                {_t("lastYearsCorporateBalanceSheet")}
                              </h4>
                              <p className="info-text-p">
                                {"_tuploadPdfPngJpgOrJpeg"}
                              </p>
                              <label>
                                <input
                                  accept="application/pdf, image/jpeg, image/png, image/jpg"
                                  multiple={false}
                                  data-val={constants.corporate_balance_sheet}
                                  type="file"
                                  onChange={handleUploadFile}
                                  className="d-none"
                                />
                                <span className="btn btngreen">
                                  {_t("upload")}{" "}
                                  <img src="/images/uploadIcon.svg" alt="" />
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                        {docFound.has(constants.corporate_PL) ? (
                          <DocCard
                            file_data={docFound.get(constants.corporate_PL)}
                            handleUploadFile={handleUploadFile}
                            doc_type={constants.corporate_PL}
                          />
                        ) : (
                          <div className="col-md-4 ">
                            <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                              <img
                                src="/images/uploadDocPlaceholder.svg"
                                alt=""
                              />
                              <h4 data-val={constants.corporate_PL}>
                                {_t("lastYearsCorporatePAndL")}
                              </h4>
                              <p className="info-text-p">
                                {"_tuploadPdfPngJpgOrJpeg"}
                              </p>
                              <label>
                                <input
                                  accept="application/pdf, image/jpeg, image/png, image/jpg"
                                  multiple={false}
                                  data-val={constants.corporate_PL}
                                  type="file"
                                  onChange={handleUploadFile}
                                  className="d-none"
                                />
                                <span className="btn btngreen">
                                  {_t("upload")}{" "}
                                  <img src="/images/uploadIcon.svg" alt="" />
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  {user?.employment_status == constants.retired && (
                    <>
                      {docFound.has(constants.pension_certificate) ? (
                        <DocCard
                          file_data={docFound.get(
                            constants.pension_certificate
                          )}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.pension_certificate}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.pension_certificate}>
                              {_t("pensionCertificate")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.pension_certificate}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {docFound.has(constants.last_revalorization_letter) ? (
                        <DocCard
                          file_data={docFound.get(
                            constants.last_revalorization_letter
                          )}
                          handleUploadFile={handleUploadFile}
                          doc_type={constants.last_revalorization_letter}
                        />
                      ) : (
                        <div className="col-md-4 ">
                          <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                            <img
                              src="/images/uploadDocPlaceholder.svg"
                              alt=""
                            />
                            <h4 data-val={constants.last_revalorization_letter}>
                              {_t("lastRevalorizationLetter")}
                            </h4>
                            <p className="info-text-p">
                              {_t("uploadPdfPngJpgOrJpeg")}
                            </p>
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.last_revalorization_letter}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row clave-box-bottom">
            {(user?.employment_status == constants.employed ||
              user?.employment_status == constants.self_employed) && (
              <>
                {!haveValue(user?.haveClavePin) ||
                  (user?.haveClavePin && <h3>{_t("fromClavePin")}</h3>)}
                {docFound.has(constants.previous_working_life_certificate) ? (
                  <DocCard
                    file_data={docFound.get(
                      constants.previous_working_life_certificate
                    )}
                    doc_type={constants.previous_working_life_certificate}
                    handleUploadFile={handleUploadFile}
                    allowReUpload={
                      !(!haveValue(user?.haveClavePin) || user?.haveClavePin)
                    }
                  />
                ) : (
                  <div className="col-md-4 ">
                    <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                      <img src="/images/uploadDocPlaceholder.svg" alt="" />
                      <h4
                        data-val={constants.previous_working_life_certificate}
                      >
                        {_t("workingLifeCertificate")}
                      </h4>
                      <p className="info-text-p">
                        {_t("uploadPdfPngJpgOrJpeg")}
                      </p>
                      {haveValue(user?.haveClavePin) && !user?.haveClavePin && (
                        <label>
                          <input
                            accept="application/pdf, image/jpeg, image/png, image/jpg"
                            multiple={false}
                            data-val={
                              constants.previous_working_life_certificate
                            }
                            type="file"
                            onChange={handleUploadFile}
                            className="d-none"
                          />
                          <span className="btn btngreen">
                            {_t("upload")}{" "}
                            <img src="/images/uploadIcon.svg" alt="" />
                          </span>
                        </label>
                      )}
                    </div>
                  </div>
                )}
                {docFound.has(constants.income_tax_return) ? (
                  <DocCard
                    file_data={docFound.get(constants.income_tax_return)}
                    doc_type={constants.income_tax_return}
                    handleUploadFile={handleUploadFile}
                    allowReUpload={
                      !(!haveValue(user?.haveClavePin) || user?.haveClavePin)
                    }
                  />
                ) : (
                  <div className="col-md-4 ">
                    <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                      <img src="/images/uploadDocPlaceholder.svg" alt="" />
                      <h4 data-val={constants.income_tax_return}>
                        {_t("lastAnnualTaxReportModel100")}
                      </h4>
                      <p className="info-text-p">
                        {_t("uploadPdfPngJpgOrJpeg")}
                      </p>
                      {haveValue(user?.haveClavePin) && !user?.haveClavePin && (
                        <label>
                          <input
                            accept="application/pdf, image/jpeg, image/png, image/jpg"
                            multiple={false}
                            data-val={constants.income_tax_return}
                            type="file"
                            onChange={handleUploadFile}
                            className="d-none"
                          />
                          <span className="btn btngreen">
                            {_t("upload")}{" "}
                            <img src="/images/uploadIcon.svg" alt="" />
                          </span>
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {user?.employment_status == constants.self_employed && (
              <>
                {docFound.has(constants.fractioned_tax_return) ? (
                  <DocCard
                    file_data={docFound.get(constants.fractioned_tax_return)}
                    doc_type={constants.fractioned_tax_return}
                    handleUploadFile={handleUploadFile}
                    allowReUpload={
                      !(!haveValue(user?.haveClavePin) || user?.haveClavePin)
                    }
                  />
                ) : (
                  <div className="col-md-4 ">
                    <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                      <img src="/images/uploadDocPlaceholder.svg" alt="" />
                      <h4 data-val={constants.fractioned_tax_return}>
                        {_t("lastFractionatedTaxReportModel130")}
                      </h4>
                      <p className="info-text-p">
                        {_t("uploadPdfPngJpgOrJpeg")}
                      </p>
                      {haveValue(user?.haveClavePin) && !user?.haveClavePin && (
                        <label>
                          <input
                            accept="application/pdf, image/jpeg, image/png, image/jpg"
                            multiple={false}
                            data-val={constants.fractioned_tax_return}
                            type="file"
                            onChange={handleUploadFile}
                            className="d-none"
                          />
                          <span className="btn btngreen">
                            {_t("upload")}{" "}
                            <img src="/images/uploadIcon.svg" alt="" />
                          </span>
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {user?.employment_status == constants.self_employed &&
              user?.is_freelancer == true && (
                <>
                  {docFound.has(constants.quarterly_vat) ? (
                    <DocCard
                      file_data={docFound.get(constants.quarterly_vat)}
                      doc_type={constants.quarterly_vat}
                      handleUploadFile={handleUploadFile}
                      allowReUpload={
                        !(!haveValue(user?.haveClavePin) || user?.haveClavePin)
                      }
                    />
                  ) : (
                    <div className="col-md-4 ">
                      <div className=" doc_upload_div_frame tenant-revisit-pdf-box">
                        <img src="/images/uploadDocPlaceholder.svg" alt="" />
                        <h4 data-val={constants.quarterly_vat}>
                          {_t("lastQuarterlyVATTaxForm303")}
                        </h4>
                        <p className="info-text-p">
                          {_t("uploadPdfPngJpgOrJpeg")}
                        </p>
                        {haveValue(user?.haveClavePin) &&
                          !user?.haveClavePin && (
                            <label>
                              <input
                                accept="application/pdf, image/jpeg, image/png, image/jpg"
                                multiple={false}
                                data-val={constants.quarterly_vat}
                                type="file"
                                onChange={handleUploadFile}
                                className="d-none"
                              />
                              <span className="btn btngreen">
                                {_t("upload")}{" "}
                                <img src="/images/uploadIcon.svg" alt="" />
                              </span>
                            </label>
                          )}
                      </div>
                    </div>
                  )}
                </>
              )}
          </div>
        </>
      </div>
    </>
  );
};

export default TenantProvideDocumentNew;
