import useTranslator from "../../../hooks/useTranslator";
import searchicon from "../../../assets/img/s.png";
import { Accordion } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import LandLordContext from "../../../context/landlord/LandLordContext";
import constants from "../../../Utils/constants";
import TenantPaymentList from "../../../components/Landlord/TenantPaymentList";
import { haveValue } from "../../../helper/common";
import { useNavigate } from "react-router-dom";

const PropertyPaymentList = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate()
  const [currentActiveAccordion, setCurrentActiveAccordion] = useState(0);
  const queryParam = new URLSearchParams(window.location.search)
  const isConfirmation = queryParam.get("confirmation");
  const assigned_prop_id = queryParam.get("assigned_prop");
  const landLordContext = useContext(LandLordContext);
  const { fetchAssignedProperties, setLandlordLoading, fetchAssignedDetails } = landLordContext;
  const landlordId = localStorage.getItem("saveduserid");
  const [paymentSet, setPaymentSet] = useState([]);
  const [paymentNotSet, setPaymentNotSet] = useState([]);
  const [defaultPaymentSet, setDefaultPaymentSet] = useState([]);
  const [defaultPaymentNotSet, setDefaultPaymentNotSet] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [showNoti, setShowNoti] = useState(false)
  const [recentAwarded, setRecentAwarded] = useState({})

  useEffect(() => {
    setLandlordLoading(true);
    fetchAssignedProperties(landlordId)
      .then((res) => {
        const temp_not = [];
        const temp_yes = [];
        console.log(res);
        res.result.map((assigned) => {
          if (assigned.status == constants.TENANT_PENDING) {
            temp_not.push(assigned);
          } else if (assigned.status == constants.AWARDED) {
            temp_yes.push(assigned);
          }
        });
        setPaymentNotSet(temp_not);
        setPaymentSet(temp_yes);
        setDefaultPaymentNotSet(temp_not);
        setDefaultPaymentSet(temp_yes);
        setLandlordLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLandlordLoading(false);
      });
  }, []);

  if (haveValue(isConfirmation) && haveValue(assigned_prop_id) && isConfirmation) {
    navigate('/payment/property/list')
    fetchAssignedDetails(assigned_prop_id)
      .then(res => {
        const data = res.result;
        setRecentAwarded(data)
        setShowNoti(true);
        setTimeout(() => {
          setShowNoti(false)
        }, 10000);
      })
      .catch(err => {
        console.log(err)
      })

  }

  const handleAccordionSelect = (eventKey) => {
    setCurrentActiveAccordion(parseInt(eventKey));
  };

  const handleSearchVal = (e) => {
    const value = e.target.value;
    setSearchVal(value);
    const searchResults = [];
    const searchResults1 = [];

    if (!haveValue(value)) {
      setPaymentNotSet(defaultPaymentNotSet);
      setPaymentSet(defaultPaymentSet);
    } else {

      defaultPaymentNotSet.forEach((obj) => {
        console.log(obj, "Object is here")
        if (obj.tenant_id && obj.tenant_id.fname && obj.tenant_id.lname) {
          const { fname, lname } = obj?.tenant_id;
          const fullName = `${fname} ${lname}`;
          const property_addr = `${obj?.property_id?.street} ${obj?.property_id?.street_number} ${obj?.property_id?.floor_number} ${obj?.property_id?.flat_number}`
          // Check if the search text matches any part of the fname or lname
          if (
            fname.toLowerCase().includes(value.toLowerCase()) ||
            lname.toLowerCase().includes(value.toLowerCase()) ||
            fullName.toLowerCase().includes(value.toLowerCase()) ||
            property_addr.toLowerCase().includes(value.toLowerCase())
          ) {
            // If there's a match, add the object to the searchResults array
            searchResults.push(obj);
          }
        }
      });

      setPaymentNotSet(searchResults);

      defaultPaymentSet.forEach((obj) => {

        if (obj.tenant_id && obj.tenant_id.fname && obj.tenant_id.lname) {
          const { fname, lname } = obj?.tenant_id;
          const fullName = `${fname} ${lname}`;
          const property_addr = `${obj?.property_id?.street} ${obj?.property_id?.street_number} ${obj?.property_id?.floor_number} ${obj?.property_id?.flat_number}`

          // Check if the search text matches any part of the fname or lname
          if (
            fname.toLowerCase().includes(value.toLowerCase()) ||
            lname.toLowerCase().includes(value.toLowerCase()) ||
            fullName.toLowerCase().includes(value.toLowerCase()) ||
            property_addr.toLowerCase().includes(value.toLowerCase())
          ) {
            // If there's a match, add the object to the searchResults array
            searchResults1.push(obj);
          }
        }
      });

      setPaymentSet(searchResults1);
    }
    console.log(searchResults, searchResults1);
  };

  return (
    <>
      <div className="tenant-review">
        {
          showNoti &&
          <div className="tenant-review-content">
            <div className="row">
              <div className="col-lg-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="77" height="81" viewBox="0 0 77 81" fill="none">
                  <path d="M38.5 2.16797L48.5683 9.51263L61.0323 9.48964L64.8599 21.35L74.9569 28.6563L71.0833 40.5013L74.9569 52.3463L64.8599 59.6526L61.0323 71.513L48.5683 71.49L38.5 78.8346L28.4318 71.49L15.9677 71.513L12.1401 59.6526L2.04309 52.3463L5.91667 40.5013L2.04309 28.6563L12.1401 21.35L15.9677 9.48964L28.4318 9.51263L38.5 2.16797Z" fill="#223637" stroke="#223637" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M28.3712 26.568H24.9962V23.168H51.9962V26.568H48.6212V29.968C48.6212 32.7135 47.2442 34.9235 45.5095 36.7289C44.3232 37.9631 42.8803 39.1004 41.4173 40.168C42.8803 41.2356 44.3232 42.3729 45.5095 43.6071C47.2442 45.4125 48.6212 47.6225 48.6212 50.368V53.768H51.9962V57.168H24.9962V53.768H28.3712V50.368C28.3712 47.6225 29.7482 45.4125 31.483 43.6071C32.6693 42.3729 34.1121 41.2356 35.5752 40.168C34.1121 39.1004 32.6693 37.9631 31.483 36.7289C29.7482 34.9235 28.3712 32.7135 28.3712 29.968V26.568ZM31.7462 26.568V29.968C31.7462 31.4725 32.4786 32.875 33.9079 34.3642C35.1347 35.6409 36.7328 36.836 38.4962 38.0906C40.2597 36.836 41.8577 35.6409 43.0845 34.3642C44.5138 32.875 45.2462 31.4725 45.2462 29.968V26.568H31.7462ZM38.4962 42.2454C36.7328 43.5 35.1347 44.6951 33.9079 45.9718C32.4786 47.461 31.7462 48.8635 31.7462 50.368V53.768H45.2462V50.368C45.2462 48.8635 44.5138 47.461 43.0845 45.9718C41.8577 44.6951 40.2597 43.5 38.4962 42.2454Z" fill="#A3EB64" />
                </svg>
              </div>
              <div className="col-lg-7">
                <div className="review-content">
                  <h4>{_t("tenantReview")}</h4>
                  <p>{haveValue(recentAwarded?.tenant_id?.fname) ? recentAwarded?.tenant_id?.fname : ''} {haveValue(recentAwarded?.tenant_id?.lname) ? recentAwarded?.tenant_id?.lname : ''}{_t("hasNowTwentyFourHourTimeToSetUpThePaymentDetailsFor")}{`${recentAwarded?.property_id?.street} - ${recentAwarded?.property_id?.street_number}, ${recentAwarded?.property_id?.floor_number}-${recentAwarded?.property_id?.flat_number}`}</p>

                </div>

              </div>
            </div>
            <div className="close-btn">
              < a href={undefined} onClick={e => setShowNoti(false)}>{_t("close")}</a>
            </div>
          </div>
        }
        <div className="Search-bar secondrysearchbar">
          <input
            type="text"
            className="custom-input mb-0"
            placeholder={_t("searchPropertyTenant")}
            onChange={handleSearchVal}
            value={searchVal}
          />
          <a href="#">
            <img src={searchicon} alt="" />
          </a>
        </div>
        <div>
          <Accordion
            className="primaryaccordion  secondryaccordion"
            defaultActiveKey={currentActiveAccordion.toString()}
            onSelect={handleAccordionSelect}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header className="primaryaccordion-header ">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <span className="maintitle">
                    {_t("waitingForTenant")} (
                    {haveValue(paymentNotSet) && haveValue(paymentNotSet.length)
                      ? paymentNotSet.length
                      : 0}
                    )
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body className="primaryaccordion-body secondryaccoridonbody">
                {
                  paymentNotSet.map((payment) => (
                    <TenantPaymentList
                      status={constants.TENANT_PENDING}
                      data={payment}
                    />
                  )
                  )
                }
                {
                  haveValue(paymentNotSet.length) && paymentNotSet.length == 0 ?
                    _t("noDetails")
                    :
                    <></>
                }
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="primaryaccordion-header ">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <span className="maintitle">
                    {_t("inPayment")} (
                    {haveValue(paymentSet) && haveValue(paymentSet.length)
                      ? paymentSet.length
                      : 0}
                    )
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body className="primaryaccordion-body secondryaccoridonbody">
                {
                  paymentSet.map((payment) => (
                    <TenantPaymentList
                      status={constants.AWARDED}
                      data={payment}
                    />
                  )
                  )
                }
                {
                  haveValue(paymentSet.length) && paymentSet.length == 0 ?
                    _t("noDetails")
                    :
                    <></>
                }
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default PropertyPaymentList;
