import React, { Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/auth/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useTranslator from "../hooks/useTranslator";
import axiosInstance from "../services/authWrapper";

const ForgotPassword = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { setUserType } = authContext;

  const [pageCount, setPagecount] = useState(0);
  const [useremail, setUseremail] = useState({
    email: "",
    checkedTenant: false,
    checkedLandlord: false,
  });
  const [changepassword, setChangepassword] = useState({
    id: "",
    otp: "",
    type: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleUsertype = (e) => {
    setUserType(e.target.value);
    if (e.target.value == "landlord") {
      setUseremail({
        ...useremail,
        checkedLandlord: true,
        checkedTenant: false,
        type: "landlord",
      });
      setChangepassword({ ...changepassword, type: "landlord" });
    }
    if (e.target.value == "tenant") {
      setUseremail({
        ...useremail,
        checkedLandlord: false,
        checkedTenant: true,
        type: "tenant",
      });
      setChangepassword({ ...changepassword, type: "tenant" });
    }
  };

  const onChange_getUser = (e) => {
    setUseremail({ ...useremail, [e.target.name]: e.target.value });
  };
  const onSubmit_getuser = async (e) => {
    e.preventDefault();
    if (
      useremail.checkedLandlord == false &&
      useremail.checkedTenant == false
    ) {
      toast.error(_t("pleaseSpecifyUserType"));
    }
    console.log(useremail);
    await axiosInstance
      .post(`${process.env.REACT_APP_SERVER_URL}/users/find-user`, useremail, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        console.log(res);
        setChangepassword({ ...changepassword, id: res.data.user._id });
        toast.success(_t("otpSentToYourRegisteredEmailId"));
        setPagecount(1);
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.status);
      });
  };
  const onChange = (e) => {
    setChangepassword({ ...changepassword, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(changepassword);
    await axiosInstance
      .post(
        `${process.env.REACT_APP_SERVER_URL}/users/change-password/`,
        changepassword,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        toast.success(res.data.msg);
        // navigate('/login')
        navigate("/auth");
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };

  if (pageCount == 0) {
    return (
      <Fragment>
        <div className="forgetpassword-page">
          <Container maxWidth="sm">
            <Box sx={{ bgcolor: "", height: "100vh" }}>
              <h2>{_t("forgotPassword")} </h2>
              <div className="forgetpassword-form">
                <form onSubmit={onSubmit_getuser}>
                  <label className="label">{_t("username")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="demo@xyz"
                    onChange={onChange_getUser}
                  />
                  <div className="login-type">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="tenant"
                      id="flexCheckDefaultTenant"
                      checked={useremail.checkedTenant}
                      onChange={handleUsertype}
                    />
                    <label className="form-check-label">{_t("tenant")}</label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="landlord"
                      id="flexCheckDefaultLandlord"
                      checked={useremail.checkedLandlord}
                      onChange={handleUsertype}
                    />
                    <label className="form-check-label">{_t("landlord")}</label>
                  </div>
                  <button className="btn btn-primary">
                    {_t("searchMyAccount")}
                  </button>
                </form>
              </div>
            </Box>
          </Container>
        </div>
      </Fragment>
    );
  }

  if (pageCount == 1) {
    return (
      <Fragment>
        <div className="forgetpassword-page">
          <Container maxWidth="sm">
            <Box sx={{ bgcolor: "", height: "100vh" }}>
              <h2>{_t("forgotPassword")} </h2>
              <div className="forgetpassword-form">
                <form onSubmit={onSubmit}>
                  <label className="label">{_t("username")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="demo@xyz"
                    readOnly={true}
                    onChange={onChange}
                  />
                  <label className="label">{_t("enterOtp")}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="otp"
                    placeholder="******"
                    onChange={onChange}
                  />
                  <label className="label">{_t("password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="*********"
                    onChange={onChange}
                  />
                  <label className="label">{_t("confirmPassword")}</label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    placeholder="*********"
                    onChange={onChange}
                  />
                  <button className="btn btn-primary">
                    {_t("changePassword")}
                  </button>
                </form>
              </div>
            </Box>
          </Container>
        </div>
      </Fragment>
    );
  }
};

export default ForgotPassword;
