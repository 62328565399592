import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import useTranslator from "../../hooks/useTranslator";
import i18next from "i18next";
import constants from "../../Utils/constants";
import AuthContext from "../../context/auth/AuthContext";
import TenantContext from "../../context/tenant/TenantContext";
import Button from "react-bootstrap/Button";
import { BsLinkedin } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPInput from "otp-input-react";
import { haveValue } from "../../helper/common";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useCookies } from "react-cookie";
import { FaArrowLeft } from "react-icons/fa6";
import SelectUserTypeScreen from "./screens/SelectUserTypeScreen";
import SelectUserEmailScreen from "./screens/SelectUserEmailScreen";
import UserOtpScreen from "./screens/UserOtpScreen";
import LoginWithCredentialsScreen from "./screens/LoginWithCredentialsScreen";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { MdInfoOutline } from "react-icons/md";
import EmailField from "../../components/Auth/EmailField";

const LanguageToggle = ({ currentLang, onChange }) => (
  <div className="menu-togglebar">
    {["en-us", "es"].map((lang) => (
      <label className="customradiobtn" key={lang}>
        <input
          type="radio"
          onChange={onChange}
          checked={currentLang === lang}
          name="radio"
          value={lang}
        />
        <span className="checkmark1">
          {(lang === "en-us" ? "en" : lang).toUpperCase()}
        </span>
      </label>
    ))}
  </div>
);

const TermsLinks = ({ currentLang }) => {
  const getLink = (path) =>
    `https://skortorent.com/${currentLang === "es" ? "es/" : ""}${path}`;
  return (
    <div className="terms-bar">
      <Link className="link-bar" to={getLink("terms-conditions.html")}>
        Terms and Conditions
      </Link>
      <Link className="link-bar" to={getLink("privacy-policy.html")}>
        Privacy Policy
      </Link>
      <Link className="link-bar" to={getLink("cookies.html")}>
        Cookies Policy
      </Link>
    </div>
  );
};

let timer = 60;
let myInterval = null;
const Auth = () => {
  const { _t } = useTranslator();
  const snackbar = useSnackbar();
  const [cookies, removeCookie] = useCookies(["sharedProfileLink"]);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const logincode = queryParameters.get("code");
  const { hash } = useLocation();

  //context
  const {
    setUserType,
    getUserByEmail,
    toggleLogin,
    loadUser,
    register,
    sendOTPES,
    sendOTP,
    setLoading,
  } = useContext(AuthContext);
  const { shareProfile } = useContext(TenantContext);
  //states
  const [currentScreen, setCurrentScreen] = useState("selectUserType"); //"verifyOTP"selectUserType
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng")?.toLowerCase() || "en-us"
  );
  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [loginPasswordType, setLoginPasswordType] = useState("password");
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [rePasswordType, setRePasswordType] = useState("password");
  const [pwdErrorMsg, setpwdErrorMsg] = useState("");
  const [userLogin, setUserLogin] = useState({
    _id: null,
    email: "",
    password: "",
    confirmPassword: "",
    type: "tenant",
    checkedTenant: true,
    checkedLandlord: false,
    user_type:
      window.location.hostname === "alquilatuhogar.skortorent.com"
        ? constants.MACC
        : constants.SKOR,
    checked: false,
    qrCode_signup: false,
    analysisCompleted: true,
    requestProfileLink: null,
  });
  const { checkedLandlord, checkedTenant, type, user_type } = userLogin;
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [authAction, setAuthAction] = useState("login");
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [presentUserData, setPresentUserData] = useState({});
  const [showResend, setShowResend] = useState(false);
  const [showTimer, setShowTimer] = useState(timer);

  //Useffect for linkedin login and register
  useEffect(() => {
    if (logincode) {
      setLoading(true);
      console.log(logincode);
      let user_type = localStorage.getItem("usertype");
      if (!user_type) {
        toast.error(_t("pleaseSelectYourUserType"));
      } else {
        axios
          .get(
            `${
              process.env.REACT_APP_SERVER_URL
            }/users/linkedin-login/${logincode}?usertype=${user_type}&user_type=${
              window.location.hostname == "alquilatuhogar.skortorent.com"
                ? constants.MACC
                : constants.SKOR
            }`,
            {
              headers: {
                "Accept-Language": localStorage.getItem("i18nextLng"),
              },
            }
          )
          .then(async (res) => {
            let serverResponse = res;
            console.log(res.data);
            localStorage.setItem("token", serverResponse.data.token);
            localStorage.setItem("is_verified", true);
            toggleLogin(true);
            console.log(user_type, "usertype1");
            await loadUser(user_type);
            setLoading(false);

            if (
              haveValue(cookies.sharedProfileLink) &&
              user_type == "landlord"
            ) {
              if (haveValue(res?.data?.user?._id)) {
                shareProfile(
                  cookies.sharedProfileLink,
                  res?.data?.user?._id,
                  "null",
                  "full",
                  { shareType: "tLink" }
                )
                  .then((res) => {
                    removeCookie("sharedProfileLink", { maxAge: 1800 });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
            if (
              haveValue(cookies.requestProfileLink) &&
              user_type == "tenant"
            ) {
              if (haveValue(res?.data?.user?._id)) {
                if (res?.data?.user?.docSigned) {
                  shareProfile(
                    res?.data?.user?._id,
                    cookies.requestProfileLink,
                    "null",
                    "full",
                    { shareType: "lLink" }
                  )
                    .then((res) => {
                      removeCookie("requestProfileLink", { maxAge: 1800 });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  navigate("/profile-shared?agreement=true");
                }
              }
            }

            if (serverResponse.data.user.has_profile) {
              if (
                serverResponse.data.user.role == "tenant" &&
                !serverResponse.data.user.kyc_success
              ) {
                navigate("/tenant");
              } else {
                navigate("/home");
                //setting properties in landlord state
                console.log("profile exist of landlord");
              }
            } else {
              if (localStorage.getItem("usertype") == "tenant") {
                navigate("/tenant");
              } else {
                navigate("/home");
              }
              console.log("profile  NOT exist");
            }
            //navigate('/home');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            navigate("/auth");
            // navigate("/login");
            //toast.error(err.data.message);
            toast.error(_t("pleaseSignUpFirstUsingLinkedin"));
          });
      }
    }
  }, [logincode]);

  useEffect(() => {
    const useeffectFunc = async () => {
      if (
        haveValue(queryParameters.get("lng")) &&
        queryParameters.get("lng") == "es"
      ) {
        await i18next.changeLanguage("es");
        setCurrentLang("es");
      } else if (
        haveValue(localStorage.getItem("i18nextLng")) &&
        localStorage.getItem("i18nextLng") == "es"
      ) {
        await i18next.changeLanguage("es");
        setCurrentLang("es");
      } else {
        await i18next.changeLanguage("en-us");
        setCurrentLang("en-us");
      }

      if (hash === "#tenant") {
        setUserType("tenant");
        setUserLogin((prev) => ({
          ...prev,
          checkedLandlord: false,
          checkedTenant: true,
          type: "tenant",
        }));
      } else if (hash === "#landlord") {
        setUserType("landlord");
        setUserLogin((prev) => ({
          ...prev,
          checkedLandlord: true,
          checkedTenant: false,
          type: "landlord",
        }));
      } else {
        setUserType("tenant");
        setUserLogin((prev) => ({
          ...prev,
          checkedLandlord: false,
          checkedTenant: true,
          type: "tenant",
        }));
      }
    };

    useeffectFunc();
  }, []);

  useEffect(() => {
    if (
      haveValue(cookies.requestProfileLink) &&
      localStorage.getItem("usertype") == "tenant"
    ) {
      setUserLogin((prev) => ({
        ...prev,
        qrCode_signup: true,
        requestProfileLink: haveValue(cookies.requestProfileLink)
          ? cookies.requestProfileLink
          : null,
      }));
    }
  }, [cookies?.requestProfileLink]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let is_verified = localStorage.getItem("is_verified");
    let user_type = localStorage.getItem("usertype");
    if (token && is_verified) {
      toggleLogin(true);
      console.log("user is logged in");
      console.log(user_type, "usertype2");
      loadUser(user_type);
      if (user_type == "tenant") {
        navigate("/tenant");
      } else {
        navigate("/home");
      }
    }
  }, [toggleLogin, navigate]);

  /**
   * ***************************************************************
   * Common Functions
   * ***************************************************************
   */
  const handleRedirectToLandingPage = () => {
    window.location.replace("https://skortorent.com/");
  };

  const getLogo = () =>
    user_type === constants.MACC ? "/images/Macc.svg" : "../images/logo.svg";

  const getUserImage = () =>
    type === "tenant"
      ? "../images/TenantUserRole.webp"
      : "../images/LandlordUserRole.webp";

  /**
   * ***************************************************************
   * Handle Language
   * ***************************************************************
   */
  const handleLanguageChange = async (e) => {
    const langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    setCurrentLang(langVal);
  };

  /**
   * ***************************************************************
   *  Handle User Type (Tenant/Landlord)
   * ***************************************************************
   */
  const handleUsertype = (e) => {
    const newType = e.target.value;
    setUserType(newType);
    setUserLogin((prev) => ({
      ...prev,
      checkedLandlord: newType === "landlord",
      checkedTenant: newType === "tenant",
      type: newType,
    }));
  };

  /**
   * ***************************************************************
   *  Function to validate mail
   * ***************************************************************
   */
  const onChangeHandler = (event) => {
    if (event.target.name === "email")
      setUserLogin({
        ...userLogin,
        [event.target.name]: event.target.value.toLowerCase(),
      });
    else
      setUserLogin({
        ...userLogin,
        [event.target.name]: event.target.value,
      });
  };

  /**
   * ***************************************************************
   *  Function to validate mail
   * ***************************************************************
   */
  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error(_t("invalidEmailAddress"));
    return false;
  };

  /**
   * ***************************************************************
   *  GET USER DETAIL BY EMAIL
   * ***************************************************************
   */
  const emailSubmitHandler = async (event) => {
    event.preventDefault();

    let email = userLogin.email;
    let type = userLogin.type;

    //validate email
    if (!validateEmail(email)) {
      return;
    }

    try {
      setDisabled(false);
      const response = await getUserByEmail({ email, type });
      if (response) {
        const user = response.user;
        if (user) {
          setUserLogin((prev) => {
            return {
              ...prev,
              _id: user._id,
            };
          });
          console.log(user, "user");

          //set user is verified
          setIsUserVerified(user.verified === true ? true : false);
          setPresentUserData(user);

          //show otp screen
          setCurrentScreen("verifyOTP");

          //send otp to mail
          let usertype = localStorage.getItem("usertype");
          if (currentLang === "es") await sendOTPES(user._id, usertype);
          else await sendOTP(user._id, usertype);

          runTimer();
        } else {
          //show Register screen
          setCurrentScreen("registerUser");
          setAuthAction("register");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisabled(false);
    }
  };

  const otpinputChange = (e) => {
    setOtp(e);
  };
  console.log(otp);
  const verifyOtpHandler = async (event) => {
    event.preventDefault();
    try {
      if (haveValue(otp) && otp.length === 6) {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/${userLogin._id}`,
          { type: userLogin.type, otp: otp },
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        );
        if (response.data) {
          if (authAction === "login" && isUserVerified) {
            redirectFun(response.data);
          } else {
            let usertype = localStorage.getItem("usertype");
            if (response.data.status === "verified") {
              snackbar.showMessage(_t("accountVerifiedSuccessfully"));
              localStorage.setItem("is_verified", true);
              console.log(usertype, "usertype3");
              loadUser(usertype);
              if (
                haveValue(cookies.sharedProfileLink) &&
                usertype === "landlord"
              ) {
                if (haveValue(response?.data?.user?._id)) {
                  shareProfile(
                    cookies.sharedProfileLink,
                    response?.data?.user?._id,
                    "null",
                    "full",
                    { shareType: "tLink" }
                  )
                    .then((res) => {
                      removeCookie("sharedProfileLink", { maxAge: 1800 });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
              navigate("/stepform");
            }
          }
        }
      } else {
        console.log("ERROR HERE");
        throw new Error(_t("invalidOtp"));
      }
    } catch (error) {
      let serverResponse = error;
      console.log(serverResponse);
      toast.error(serverResponse.response.data.errormsg || _t("invalidOtp"));
    } finally {
      setDisabled(false);
    }
  };

  const redirectFun = (data) => {
    //LogIn Message
    snackbar.showMessage(_t("loggedInSuccessfully"));

    const token = data.token;
    const is_verified = true;
    const usertype = localStorage.getItem("usertype");
    const saveduserid = data.user._id;
    const external_customer_id = data.user._id;
    const user = data.user;

    if (window.location.host === process.env.REACT_APP_MACC_URL_NAME) {
      if (token && is_verified) {
        const app_type = "macc";
        // Create a query string from the constants
        const queryParams = new URLSearchParams({
          token: token || "", // Append token
          usertype: usertype || "", // Append usertype
          is_verified: is_verified || "", // Append is_verified
          saveduserid: saveduserid || "", // Append saveduserid
          external_customer_id: external_customer_id || "", // Append external_customer_id
          app_type: app_type || "", // // Append app_type
        });
        // Redirect to the new URL with query parameters
        document.body.innerHTML = "";
        window.location.replace(
          `${window.location.protocol}//${
            process.env.REACT_APP_URL_NAME
          }/redirect?${queryParams.toString()}`
        );
        return false;
      }
    }

    //share profile
    if (haveValue(cookies.sharedProfileLink) && userLogin.type === "landlord") {
      if (haveValue(data?.user?._id)) {
        shareProfile(
          cookies.sharedProfileLink,
          data?.user?._id,
          "null",
          "full",
          { shareType: "tLink" }
        )
          .then((res) => {
            removeCookie("sharedProfileLink", { maxAge: 1800 });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    toggleLogin(true);

    //Set Local Storage
    localStorage.setItem("token", token);
    localStorage.setItem("is_verified", true);
    localStorage.setItem("saveduserid", saveduserid);
    localStorage.setItem("external_customer_id", external_customer_id);
    console.log(userLogin.type, "usertype4");
    loadUser(userLogin.type);

    //Redirecting
    if (!user.has_profile) {
      navigate(usertype === "tenant" ? "/tenant" : "/home");
    } else if (user.role === "tenant" && !user.kyc_success) {
      navigate("/tenant");
    } else {
      navigate(usertype === "tenant" ? "/tenant" : "/home");
    }
  };

  const loginWithCredentialsHandler = async (event) => {
    event.preventDefault();
    if (userLogin.email === "" || userLogin.password === "") {
      toast.error(_t("pleaseInputYourCredentials"));
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/login`,
        {
          email: userLogin.email,
          password: userLogin.password,
          type: userLogin.type,
        },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      );

      let data = response.data;
      if (data.token) {
        if (data.user.verified) {
          redirectFun(data);
        }
      } else {
        toast.error(_t("loginFailed"));
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.msg ||
        error.message;
      toast.error(haveValue(message) ? message : _t("loginFailed"));
      console.log(error);
    }
  };

  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  const registerSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      if (!userLogin.checked) {
        toast.error(_t("pleaseAcceptTheTermAndConditions"));
      } else if (userLogin.password === "") {
        toast.error(_t("pleaseEnterPassword"));
      } else if (userLogin.confirmPassword === "") {
        toast.error(_t("pleaseEnterReEnterPassword"));
      } else if (userLogin.password !== userLogin.confirmPassword) {
        toast.error(_t("passwordDoesNotMatch"));
      } else if (!validateEmail(userLogin.email)) {
        toast.error(_t("invalidEmailAddress"));
      } else if (!strongPassword.test(userLogin.password)) {
        toast.error(
          _t(
            "pleaseIncludeUppercaseLowercaseAndSpecialCharactersInYourPasswordAndMustBeAtleastEightCharactersLong"
          )
        );
      } else {
        const registerData = {
          email: userLogin.email,
          password: userLogin.password,
          confirmPassword: userLogin.confirmPassword,
          type: userLogin.type,
          checked: true,
          qrCode_signup: false,
          analysisCompleted: true,
          user_type: userLogin.user_type,
          qrCode_signup: userLogin?.qrCode_signup,
          requestProfileLink: userLogin?.requestProfileLink,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/users/signup`,
          registerData,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        );
        const data = response.data;
        if (data) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("saveduserid", data.user._id);
          let usertype = localStorage.getItem("usertype");
          if (localStorage.getItem("i18nextLng") == "es")
            sendOTPES(data.user._id, usertype);
          else sendOTP(data.user._id, usertype);
          setUserLogin((prev) => {
            return {
              ...prev,
              _id: data.user._id,
            };
          });
        }
        setCurrentScreen("verifyOTP");
        runTimer();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const runTimer = () => {
    if (timer >= 0) {
      myInterval = setInterval(() => {
        setShowTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            resetTimer();
          }
        });
      }, 1000);
    }
  };

  const resetTimer = () => {
    clearInterval(myInterval);
    setShowResend(true);
    setShowTimer(60);
  };

  const sendOtpFun = () => {
    const usertype = localStorage.getItem("usertype");
    if (currentLang === "es") {
      sendOTPES(userLogin._id, usertype);
    } else {
      sendOTP(userLogin._id, usertype);
    }
    setOtp("");
    setShowResend(false);
    runTimer();
  };

  const backHandler = () => {
    switch (currentScreen) {
      case "selectUserEmail":
        setCurrentScreen("selectUserType");
        break;
      case "verifyOTP":
        resetTimer();
        setShowResend(false);
        if (authAction === "login") setCurrentScreen("selectUserEmail");
        else setCurrentScreen("registerUser");
        break;
      case "registerUser":
        setCurrentScreen("selectUserEmail");
        break;
      default:
        setCurrentScreen("selectUserEmail");
    }
  };

  const handleShowLoginPassword = (val) => {
    if (val) {
      setLoginPasswordType("text");
      setShowLoginPassword((oldVal) => !oldVal);
    } else {
      setLoginPasswordType("password");
      setShowLoginPassword((oldVal) => !oldVal);
    }
  };
  const handleShowPassword = (val) => {
    if (val) {
      setPasswordType("text");
      setShowPassword((oldVal) => !oldVal);
    } else {
      setPasswordType("password");
      setShowPassword((oldVal) => !oldVal);
    }
  };
  const handleShowRePassword = (val) => {
    if (val) {
      setRePasswordType("text");
      setShowRePassword((oldVal) => !oldVal);
    } else {
      setRePasswordType("password");
      setShowRePassword((oldVal) => !oldVal);
    }
  };

  return (
    <div className="Auth-wrapper">
      <img
        className="auth-shape-center"
        src="../images/LineAuth.svg"
        alt="logo"
      />
      <div className="Auth-bar">
        <div className="authbar">
          <div className="logobar" onClick={handleRedirectToLandingPage}>
            <Container>
              <Link
                to={
                  user_type === constants.MACC
                    ? "https://alquilatuhogar.skortorent.com/"
                    : "https://skortorent.com/"
                }
              >
                <img src={getLogo()} alt="logo" />
              </Link>
            </Container>
          </div>
          <Container>
            <div className="authbar-inner">
              <div className="leftbar">
                <div className="hero-center">
                  <img
                    className={type === "tenant" ? "" : "signp_img"}
                    src={getUserImage()}
                    alt="user role"
                  />
                </div>
              </div>
              <div className="rightbar">
                <img
                  className="auth-shapetopright"
                  src="../images/handAuth.svg"
                  alt="logo"
                />
                <div className="right-contentbar">
                  {currentScreen !== "selectUserType" && (
                    <div className="backbtnbar">
                      <button
                        type="button"
                        className="backbtn"
                        onClick={backHandler}
                      >
                        <FaArrowLeft />
                        {_t("goBack")}
                      </button>
                    </div>
                  )}
                  <div className="heading_lng_div">
                    <h4>
                      {currentScreen === "selectUserType"
                        ? _t("getStartedAs")
                        : _t("continueAs")}{" "}
                      {_t(userLogin?.type)}
                    </h4>
                    <LanguageToggle
                      currentLang={currentLang}
                      onChange={handleLanguageChange}
                    />
                  </div>
                  {currentScreen === "selectUserType" && (
                    <SelectUserTypeScreen
                      checkedTenant={checkedTenant}
                      checkedLandlord={checkedLandlord}
                      handleUsertype={handleUsertype}
                      setCurrentScreen={setCurrentScreen}
                    />
                  )}
                  {currentScreen === "selectUserEmail" && (
                    <SelectUserEmailScreen
                      userLogin={userLogin}
                      emailSubmitHandler={emailSubmitHandler}
                      onChangeHandler={onChangeHandler}
                      disabled={disabled}
                    />
                  )}
                  {currentScreen === "verifyOTP" && (
                    <UserOtpScreen
                      userLogin={userLogin}
                      verifyOtpHandler={verifyOtpHandler}
                      otp={otp}
                      otpinputChange={otpinputChange}
                      showResend={showResend}
                      showTimer={showTimer}
                      sendOtpFun={sendOtpFun}
                      isUserVerified={isUserVerified}
                      setCurrentScreen={setCurrentScreen}
                      presentUserData={presentUserData}
                    />
                  )}
                  {currentScreen === "loginWithCredentials" &&
                    isUserVerified && (
                      <LoginWithCredentialsScreen
                        userLogin={userLogin}
                        onChangeHandler={onChangeHandler}
                        setCurrentScreen={setCurrentScreen}
                        handleShowLoginPassword={handleShowLoginPassword}
                        loginPasswordType={loginPasswordType}
                        showLoginPassword={showLoginPassword}
                        loginWithCredentialsHandler={
                          loginWithCredentialsHandler
                        }
                      />
                    )}
                  {currentScreen === "registerUser" && (
                    <div className="auth-form d-blockform">
                      <EmailField userLogin={userLogin} />
                      <form onSubmit={registerSubmitHandler}>
                        <div className="gridbar_block">
                          <div className="forminput sign-up-spacing">
                            <label className="forminput d-flex align-items-center gap-1">
                              {_t("enterPassword")}
                              <ul className="infodorpdown summarydropdum position-relative">
                                <li>
                                  <button
                                    type="button"
                                    className="iconinfo d-flex"
                                  >
                                    <MdInfoOutline />
                                  </button>{" "}
                                </li>
                                <ul className="tooltip">
                                  <li>
                                    {_t(
                                      "passwordMustBeAtleastEightCharactersLongAndContainANumberLowercaseUppercaseAndASpecialCharacter"
                                    )}
                                    .
                                  </li>
                                </ul>
                              </ul>
                            </label>
                            <div className="forminput password-div">
                              <input
                                type={passwordType}
                                className="form-control user-auth"
                                name="password"
                                value={userLogin?.password ?? ""}
                                placeholder={_t("password")}
                                onChange={onChangeHandler}
                              />
                              {showPassword ? (
                                <VscEye
                                  className="eye-icons"
                                  onClick={(e) => handleShowPassword(false)}
                                />
                              ) : (
                                <VscEyeClosed
                                  className="eye-icons"
                                  onClick={(e) => handleShowPassword(true)}
                                />
                              )}
                            </div>
                          </div>
                          <div className="forminput sign-up-spacing">
                            <label className="forminput d-flex align-items-center">
                              {_t("reEnterPassword")}
                            </label>
                            <div className="forminput password-div">
                              <input
                                type={rePasswordType}
                                className="form-control user-auth"
                                name="confirmPassword"
                                value={userLogin?.confirmPassword ?? ""}
                                placeholder={_t("confirmPassword")}
                                onChange={onChangeHandler}
                              />
                              {showRePassword ? (
                                <VscEye
                                  className="eye-icons"
                                  onClick={(e) => handleShowRePassword(false)}
                                />
                              ) : (
                                <VscEyeClosed
                                  className="eye-icons"
                                  onClick={(e) => handleShowRePassword(true)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="forminput checkboxtc">
                          <input
                            type="checkbox"
                            id="termsCheckbox"
                            name="termsCheckbox"
                            checked={userLogin?.checked}
                            onClick={(e) =>
                              setUserLogin((old) => ({
                                ...old,
                                checked: !old.checked,
                              }))
                            }
                          />
                          <label htmlFor="termsCheckbox" className="forminput">
                            {_t("agreeToAllThe")}{" "}
                            <a
                              href={
                                localStorage.getItem("i18nextLng") == "es"
                                  ? "https://skortorent.com/es/terms-conditions.html"
                                  : "https://skortorent.com/terms-conditions.html"
                              }
                              target="_blank"
                              className="setNoInfoColor"
                            >
                              {_t("termsAndConditions")}
                            </a>
                          </label>
                        </div>
                        <div className="btngroup">
                          <button type="submit" className="btn btnorange">
                            {" "}
                            {_t("setPasswordRegister")}
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                <div className="hero-foot">
                  <Container>
                    <TermsLinks currentLang={currentLang} />
                  </Container>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Auth;
