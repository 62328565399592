import { Col, Row } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";
import constants from "../../Utils/constants";
import { useNavigate } from "react-router-dom";
import {
  checkPayslipData,
  convertCurrencyFormat,
  fetchCompanyName,
  haveValue,
  verifyDniNie,
} from "../../helper/common";
import { MdInfoOutline } from "react-icons/md";
import tickNew from "../../assets/img/tick-new.svg";
import LandlordBlurApprove from "../Landlord/LandlordBlurApprove";
import linkedIn from "../../assets/img/linkedIn.svg";
const ProfessionalAcademicSection = (props) => {
  const {
    user,
    recordedSalary,
    showActionPrompts = false,
    landlord_user = false,
    isLandlord = false,
  } = props;
  const { _t } = useTranslator();
  const navigate = useNavigate();

  const getEmploymentStatus = () => {
    if (user?.employment_status == constants.employed) {
      return _t("employed");
    }
    if (user?.employment_status == constants.self_employed) {
      return _t("selfEmployed");
    }
    if (user?.employment_status == constants.student) {
      return _t("student");
    }
    if (user?.employment_status == constants.unemployed) {
      return _t("unemployed");
    }
  };

  console.log(recordedSalary, "recordedSalaryrecordedSalary");

  return (
    <>
      <Col
        xl={6}
        className={`mb-3 mobile-profile ${landlord_user?.account_status != "approved" && "blur-approved"
          }`}
      >
        <div className="score-professional border-pd accordion-tile position-relative">
          <h4>{_t("professionalAndAcademic")} </h4>
          <Row className="mx-0 mobile-professional">
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("employmentStatus")}:</h4>
                {user?.employment_status ? (
                  <h3>{getEmploymentStatus()}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            {user?.employment_status === constants.self_employed && (
              <>
                <Col md={6}>
                  <div className="Score-content-list">
                    <h4>{_t("employmentType")}:</h4>
                    {haveValue(user?.is_freelancer) ? (
                      <h3>
                        {user?.is_freelancer == true
                          ? _t("freelancer")
                          : _t("ownACompany")}
                      </h3>
                    ) : (
                      <h3 className="setNoInfoColor">
                        {showActionPrompts ? (
                          <a
                            href={undefined}
                            onClick={(e) => navigate("/edit-profile/tenant")}
                          >
                            +{_t("addInfo")}
                          </a>
                        ) : (
                          <p href={undefined}>{_t("noInfo")}</p>
                        )}
                      </h3>
                    )}
                  </div>
                </Col>
                {user?.is_freelancer == false && (
                  <Col md={6}>
                    <div className="Score-content-list">
                      <h4>{_t("companyName")}:</h4>
                      {user?.name_of_company ? (
                        <h3>{user?.name_of_company}</h3>
                      ) : (
                        <h3 className="setNoInfoColor">
                          {showActionPrompts ? (
                            <a
                              href={undefined}
                              onClick={(e) => navigate("/edit-profile/tenant")}
                            >
                              +{_t("addInfo")}
                            </a>
                          ) : (
                            <p href={undefined}>{_t("noInfo")}</p>
                          )}
                        </h3>
                      )}
                    </div>
                  </Col>
                )}
              </>
            )}
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("highestEducation")}:</h4>
                {user?.type_of_student ? (
                  <h3>{_t(user?.type_of_student, "fixed")}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("university")}:</h4>
                {user?.name_of_university ? (
                  <h3>{user?.name_of_university}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            {user?.employment_status === constants.employed && (
              <>
                <Col md={6}>
                  <div className="Score-content-list">
                    <h4>{_t("position")}:</h4>
                    <h3>{user?.position} </h3>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="Score-content-list">
                    {fetchCompanyName(user, _t)}
                  </div>
                </Col>
              </>
            )}

            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("linkedInProfile")}:</h4>
                {user?.linkedin_profile ? (
                  <a href={user.linkedin_profile} target="_blank" rel="noreferrer">
                    <img src={linkedIn} alt="" />
                  </a>
                ) : (
                  <h3 className="setNoInfoColor text-start">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>

            <div className="row incomes_main_div p-3">
              <h4 style={{ textAlign: "left" }}>{_t("incomeDetails")}</h4>
              <Col md={6}>
                <div className="Score-content-list">
                  <h4>{_t("declaredIncome")}:</h4>
                  {user?.current_annual_income ? (
                    <h3>
                      €{convertCurrencyFormat(user?.current_annual_income)}
                    </h3>
                  ) : (
                    <h3 className="setNoInfoColor">
                      {showActionPrompts ? (
                        <a
                          href={undefined}
                          onClick={(e) => navigate("/edit-profile/tenant")}
                        >
                          +{_t("addInfo")}
                        </a>
                      ) : (
                        <p href={undefined}>{_t("noInfo")}</p>
                      )}
                    </h3>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="Score-content-list">
                  <h4>{_t("verifiedIncomeFromBank")}:</h4>
                  {haveValue(recordedSalary?.salary) &&
                    recordedSalary?.salary ? (
                    <h3>
                      €{convertCurrencyFormat(recordedSalary?.salary)}
                      <img src={tickNew} alt="" />
                    </h3>
                  ) : (
                    <h3 className="setNoInfoColor">{_t("noInfo")}</h3>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="Score-content-list">
                  <h4>{_t("verifiedIncomeFromIRPF")}:</h4>
                  {haveValue(user?.itr_status) ? (
                    user?.itr_status == "success" ? (
                      <>
                        <h3>
                          €{convertCurrencyFormat(user?.itr_amount)}{" "}
                          {verifyDniNie(user, user?.itr_dni_nie) && (
                            <img src={tickNew} alt="" />
                          )}
                        </h3>
                      </>
                    ) : user?.itr_status == "pending" && showActionPrompts ? (
                      <h3>{_t("inProcess")}</h3>
                    ) : showActionPrompts ? (
                      <h3>{_t("unableToFetchTheAmount")} </h3>
                    ) : null
                  ) : (
                    <></>
                  )}
                </div>
              </Col>

              {checkPayslipData(user, _t)}
            </div>
          </Row>
          {isLandlord && landlord_user?.account_status != "approved" && (
            <LandlordBlurApprove landlord_user={landlord_user} />
          )}
        </div>
      </Col>
    </>
  );
};

export default ProfessionalAcademicSection;
