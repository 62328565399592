import moment from "moment";
import axiosInstance from "./authWrapper";

export const getLocalTime = (dateTime) => {
  let localTime = moment(dateTime).format("DD-MM-YYYY");
  //let localTime = moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
  return localTime;
};

export const calculateNowTime = (dateTime) => {
  let timeDiff = moment(dateTime).fromNow();
  return timeDiff;
};

export const sendUniversalMail = async (payload = null) => {
  return new Promise((resolve, reject) => {
    //resolve("Promise resolved");

    let url = `${process.env.REACT_APP_SERVER_URL}/users/send-mail`;
    axiosInstance
      .post(url, payload, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const sendSharedFeedback = async (id = null, payload = null) => {
  return new Promise((resolve, reject) => {
    //resolve("Promise resolved");
    let url = `${process.env.REACT_APP_SERVER_URL}/tenants/accept-feedback/${id}`;
    axiosInstance
      .post(url, payload, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
