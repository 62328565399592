import * as Joi from "joi-browser";
import { MdInfoOutline } from "react-icons/md";
import tickNew from "../assets/img/tick-new.svg";
import { Col } from "react-bootstrap";

export const haveValue = (val) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const withBackendUrl = (path, defaultPath = null) => {
  if (typeof path === "object") {
    let src = URL.createObjectURL(path);
    return src;
  } else {
    if (haveValue(path)) {
      path = path?.toString();
      if (path?.startsWith("http://") || path?.startsWith("https://")) {
        return path;
      } else {
        if (path.startsWith("/")) {
          path = path.substring(1);
        }
        return process.env.REACT_APP_SERVER_URL + "/" + path;
      }
    } else {
      return "";
    }
  }
};
export const toLowerCase = (data) => {
  return data.toString().toLowerCase();
};

export const convertCurrencyFormat = (amount) => {
  return new Intl.NumberFormat(localStorage.getItem("i18nextLng"), {
    currency: localStorage.getItem("i18nextLng") === "es" ? "EUR" : "USD",
  }).format(amount);
};

export const toUpperCase = (data) => {
  return data.toString().toUpperCase();
};

export const validateJOIFormField = (formFields, schema) => {
  const result = Joi.validate(formFields, schema, {
    abortEarly: false,
    stripUnknown: true,
  });
  const { error } = result;
  if (!error) {
    return null;
  } else {
    const errorData = {};
    for (let item of error.details) {
      const name = item.path[0];
      errorData[name] = item.message;
    }
    return errorData;
  }
};

export const validateJOIProperty = (schema, event) => {
  const { name, value } = event.target;
  console.log(value, "check iban value");
  const obj = { [name]: value };
  if (schema.hasOwnProperty(name)) {
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema, {
      stripUnknown: true,
    });
    const { error } = result;
    return error ? error.details[0].message : null;
  } else {
    return null;
  }
};

export const renderErrorMessage = (errors, type) => {
  if (errors && errors[type]) {
    return errors[type];
  }
  return "";
};

export const getBaseUrl = () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return baseUrl;
};

export const truncateString = (str, maxLength = 100) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

export const skipMaccUser = (user_type = null) => {
  switch (user_type) {
    case "macc":
      return true;
    default:
      return false;
  }
};

export const verifyDniNie = (user, nie_val) => {
  var result = false;
  if (user?.onfido_kyc_success) {
    if (user?.onfido_user_kyc?.onfido_doc_data?.personal_number == nie_val) {
      result = true;
    }
  }
  if (result == false) {
    if (user?.dni_nie == nie_val) {
      result = true;
    }
  }
  return result;
};

export const fetchCompanyName = (user, _t) => {
  var found = false;
  var res = null;

  if (haveValue(user?.vida_laboral_id)) {
    const response = user?.vida_laboral_id?.response;

    if (verifyDniNie(user, response?.informacionPersonal?.identificacion)) {
      const companyArr = user?.vida_laboral_id?.response?.situaciones;
      var company_name = null;
      for (let val of companyArr) {
        if (!haveValue(val?.fechaBaja)) {
          company_name = haveValue(val?.nombreEmpresa)
            ? val?.nombreEmpresa
            : val?.regimen;
          break;
        }
      }
      if (haveValue(company_name)) {
        found = true;
        res = { type: "Vida Laboral", val: company_name };
      }
    }
  }
  if (
    found == false &&
    haveValue(user?.algoanIncome?.employers) &&
    user?.algoanIncome?.employers.length > 0
  ) {
    found = true;
    res = { type: "Bank", val: user?.algoanIncome?.employers[0]?.name };
  }
  if (found == false && haveValue(user?.name_of_company)) {
    found = true;
    res = { type: "", val: user?.name_of_company };
  }
  if (found == false) {
    res = null;
  }
  if (haveValue(res)) {
    return (
      <>
        <h4 className="d-flex gap-2 align-items-center">
          {_t("nameOfCompany")}
          {haveValue(res.type) && (
            <ul className="infodorpdown summarydropdum position-relative">
              <li>
                <button className="iconinfo">
                  <MdInfoOutline />
                </button>{" "}
              </li>
              <ul className="tooltip">
                <p>
                  {_t("from")} {_t(res.type)}
                </p>
              </ul>
            </ul>
          )}
          :
        </h4>
        <h3>
          <span>{res.val}</span>{" "}
          {haveValue(res.type) && <img src={tickNew} alt="" />}
        </h3>
      </>
    );
  }
};

export const checkPayslipData = (user, _t) => {
  console.log(
    haveValue(user?.payslip_data) &&
      verifyDniNie(user, user?.payslip_data?.nieDniNumber),
    "check condition"
  );
  if (
    haveValue(user?.payslip_data) &&
    verifyDniNie(user, user?.payslip_data?.nieDniNumber)
  ) {
    const payslip_amount =
      parseFloat(user?.payslip_data?.netSalary.replace(/,/g, "")) || 0;
    return (
      <>
        <Col md={6}>
          <div className="Score-content-list">
            <h4>{_t("incomeFromPayslip")}:</h4>
            <h3>€{convertCurrencyFormat(payslip_amount)}</h3>
          </div>
        </Col>
      </>
    );
  } else {
    return (
      <Col md={6}>
        <div className="Score-content-list">
          <h4>{_t("incomeFromPayslip")}:</h4>
        </div>
      </Col>
    );
  }
};

export const toCamelCase = (str) => {
  return str
    .replace(/[^\w\s]/g, "")
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, "");
};
