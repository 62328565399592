import React, { Fragment, useEffect, useState, useContext } from "react";
import OfferItem from "./OfferItem";
import Error from "../../../components/Error";
import Loader from "../../../components/Spinner";
import Spinner from "../../../components/Spinner";
import AuthContext from "../../../context/auth/AuthContext";
import useTranslator from "../../../hooks/useTranslator";
import axiosInstance from "../../../services/authWrapper";

const Offers = () => {
  const { _t } = useTranslator();

  const authContext = useContext(AuthContext);
  const { user, userType, loadMyNotifications } = authContext;

  useEffect(() => {
    setLoading(true);
    loadOffers();
    let userid = localStorage.getItem("saveduserid");
    loadMyNotifications(userid, userType);
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [offers, setOffers] = useState([]);

  const loadOffers = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/offers/`;
    await axiosInstance
      .get(url, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        setLoading(false);
        setOffers(res.data.offers);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        console.log(err);
      });
  };

  return (
    <>
      {/* <div className='margin-div'></div> */}
      {error && <Error />}
      {/* <div className='margin-div'> */}

      <div className="tabs-box-pattern-2 tabs-box-pattern">
        <div className="row m-0">
          <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
            <div>
              {/* <Link to='/home'><img src={backicon} alt="backbtn" /></Link> */}
            </div>

            <h3>{_t("offers")}</h3>

            <div></div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <Spinner />
          ) : (
            offers.map((offer) => (
              <div className="col-lg-6 col-11  m-auto mb-md-0 ">
                <OfferItem offer={offer} key={offer?._id} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Offers;
