import { VscEye, VscEyeClosed } from "react-icons/vsc";
import EmailField from "../../../components/Auth/EmailField";
import useTranslator from "../../../hooks/useTranslator";
const LoginWithCredentialsScreen = ({
  userLogin,
  onChangeHandler,
  loginWithCredentialsHandler,
  setCurrentScreen,
  handleShowLoginPassword,
  loginPasswordType,
  showLoginPassword,
}) => {
  const { _t } = useTranslator();
  return (
    <div className="otp-Verification">
      <EmailField userLogin={userLogin} />
      <form
        className="loginform otp-verification-form"
        onSubmit={loginWithCredentialsHandler}
      >
        <p className="forminput text-start otp-heading">{_t("password")}</p>
        <div className="forminput password-div">
          <input
            type={loginPasswordType}
            className="form-control user-auth"
            name="password"
            value={userLogin?.password ?? ""}
            placeholder={_t("password")}
            onChange={onChangeHandler}
          />
          {showLoginPassword ? (
            <VscEye
              className="eye-icons"
              onClick={(e) => handleShowLoginPassword(false)}
            />
          ) : (
            <VscEyeClosed
              className="eye-icons"
              onClick={(e) => handleShowLoginPassword(true)}
            />
          )}
        </div>
        <div className="btngroup">
          <button type="submit" className="btn btnorange">
            {" "}
            {_t("login")}
          </button>
        </div>
        <div className="margin-top-12px">
          <span className="resend-otp-text">{_t("wantToLoginWithOTP")}? </span>
          <a
            className="orange_text resend-otp-text"
            href={undefined}
            onClick={(e) => {
              setCurrentScreen("verifyOTP");
            }}
          >
            {_t("clickHere")}
          </a>
        </div>
      </form>
    </div>
  );
};

export default LoginWithCredentialsScreen;
