import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { useContext, useEffect, useState } from "react";
import TenantContext from "../../context/tenant/TenantContext";
import constants from "../../Utils/constants";
import AuthContext from "../../context/auth/AuthContext";
import { toast } from "react-toastify";
import moment from "moment";
import LandLordContext from "../../context/landlord/LandLordContext";
import { haveValue } from "../../helper/common";
import { color } from "@mui/system";

const TenantRentalDetails = (props) => {
  const {
    showBig = false,
    paySectionOnTop = false,
    isLandlord = false,
  } = props;
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const landLordContext = useContext(LandLordContext);
  const tenantContext = useContext(TenantContext);
  const { setLoading } = authContext;
  var { user } = authContext;
  if (haveValue(props.user)) {
    user = props.user;
  }
  const { fetchAssignedDetails } = landLordContext;
  const { getRentalInfo, ledgerData } = tenantContext;

  const [rentalDetails, setRentalDetails] = useState({});
  const [rentDates, setRentDates] = useState([]);
  const [upcomingDate, setUpcomingDate] = useState(new Date());

  useEffect(() => {
    if (user?.rental_data?.type == constants.RENTAL_INFO) {
      getRentalInfo(user?._id)
        .then((res) => {
          setRentalDetails(res.success_res);
          console.log(res, "check rental data");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fetchAssignedDetails(user?.rental_data?.id)
        .then((res) => {
          console.log("DATA CAME INSIDE");
          setRentalDetails(res.result);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(_t("errorWhileFetchingDetails"));
        });
    }
    ledgerData(user?._id)
      .then((res) => {
        const data = res.success_res;
        checkPaymentCard(data);
        // setPaymentHistory(data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(_t("Error while fetching details"));
      });
  }, [user]);

  useEffect(() => {
    var temp_data = new Date();
    var lease_start =
      user?.rental_data?.type == constants.RENTAL_INFO
        ? moment(rentalDetails?.rent_date)
        : new Date(rentalDetails?.payment_start);

    var temp_date =
      user?.rental_data?.type == constants.RENTAL_INFO
        ? moment(rentalDetails?.rent_date).date()
        : rentalDetails?.payment_date;

    if (temp_data < lease_start) {
      temp_data = lease_start;
    }
    temp_data = new Date(temp_data);

    console.log(temp_data, "temp_datatemp_data");

    var check = new Date(
      temp_data.getFullYear(),
      temp_data.getMonth(),
      temp_date
    );

    if (check < temp_data) {
      check = new Date(
        new Date(temp_data).getFullYear() +
          (new Date(temp_data).getMonth() === 11 ? 1 : 0),
        (new Date(temp_data).getMonth() + 1) % 12,
        temp_date
      );
    }
    console.log(check, "2nd date");
    setUpcomingDate(check);
  }, [rentalDetails]);

  const checkPaymentCard = (data) => {
    var temp = [];
    var count = 0;

    for (let i = 0; i < data.length; i++) {
      const val = data[i];
      if (count == 4) {
        break; // This will exit the loop entirely
      }
      if (
        val.status == constants.COMPLETED &&
        (val.type == constants.RENT ||
          val.type == constants.PARTIAL_RENT ||
          val.type == constants.RENT_DEPOSIT)
      ) {
        temp.push(val);
        count++;
      }
    }
    temp = temp.reverse();
    setRentDates(temp);
  };

  const navigateRevisitPayment = () => {
    navigate("/tenant/rentalStep?revisit=true");
  };

  console.log(rentalDetails, "rentalDetailsrentalDetails");

  return (
    <>
      <div className="pending-steps-wrapper">
        {paySectionOnTop &&
          localStorage.getItem("usertype") == "tenant" &&
          showBig && (
            <div class={`tile-design pb-3 mb-3 border-bottom`}>
              <div class="tile-content-wrapper">
                <div class="tile-content">
                  <h4 class="tile-heading">{_t("payYourMonthlyRent")}</h4>
                  <p class="tile-desc">
                    {_t(
                      "payYourNextRentPaymentThroughSkorAndKeepEarningPointsWhileImprovingYourRentalHistory"
                    )}
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-4 tile-btns-wrapper">
                <button class="incomplete-btn" onClick={navigateRevisitPayment}>
                  <p>{_t("payNow")}</p>
                </button>
              </div>
            </div>
          )}
        <div className="d-flex justify-content-between">
          <h3
            className={`skore-details-heading ${
              paySectionOnTop ? "d-none d-md-block" : ""
            }`}
          >
            {_t("rentDetails")}
          </h3>
        </div>
        <div className="row">
          {showBig && (
            <div className="d-none d-md-block col-lg-6 padd-set-for-col">
              <div className="cust-card-wrapper">
                <h4
                  style={{ color: "#ffffff", textAlign: "start" }}
                  className="card-heading"
                >
                  {_t("address")}:
                </h4>
                <div className="card-content">
                  <img
                    className="card-content-image"
                    src="/images/home.svg"
                    alt="home-icon"
                  />
                  <h5 className="card-content-heading">
                    {haveValue(user?.rental_data?.type)
                      ? user?.rental_data?.type == constants.RENTAL_INFO
                        ? `
                    ${rentalDetails?.street || ""} ${
                            rentalDetails?.street_number || ""
                          }, ${rentalDetails?.floor_number || ""} - ${
                            rentalDetails?.flat_number || ""
                          }
                    ${rentalDetails?.province || ""}, ${
                            rentalDetails?.postcode || ""
                          }
                  `
                        : `${rentalDetails?.property_id?.street || ""} ${
                            rentalDetails?.property_id?.street_number || ""
                          }, ${
                            rentalDetails?.property_id?.floor_number || ""
                          } - ${rentalDetails?.property_id?.flat_number || ""}
                    ${rentalDetails?.property_id?.province || ""}, ${
                            rentalDetails?.property_id?.postcode || ""
                          }`
                      : _t("noPropertyLinked")}
                  </h5>
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-6 padd-set-for-col">
            <div className="Milestones-dates mobile">
              <div className="d-flex d-md-none mobile-address gap-3">
                <div className="image-container d-flex justify-content-end mobile-home-icon-box">
                  <img
                    className="image"
                    src="/images/home.svg"
                    alt="home-icon"
                  />
                </div>
                <h5 className="heading m-0">
                  {haveValue(user?.rental_data?.type)
                    ? user?.rental_data?.type == constants.RENTAL_INFO
                      ? `
                    ${rentalDetails?.street || ""} ${
                          rentalDetails?.street_number || ""
                        }, ${rentalDetails?.floor_number || ""} - ${
                          rentalDetails?.flat_number || ""
                        }
                    ${rentalDetails?.province || ""}, ${
                          rentalDetails?.postcode || ""
                        }
                  `
                      : `${rentalDetails?.property_id?.street || ""} ${
                          rentalDetails?.property_id?.street_number || ""
                        }, ${
                          rentalDetails?.property_id?.floor_number || ""
                        } - ${rentalDetails?.property_id?.flat_number || ""}
                    ${rentalDetails?.property_id?.province || ""}, ${
                          rentalDetails?.property_id?.postcode || ""
                        }`
                    : _t("noPropertyLinked")}
                </h5>
              </div>
              <div className="p-3">
                <h3 className="Milestones-head text-center text-md-start">
                  {_t("milestones")}:
                </h3>
                <div className="overflow-for-mob">
                  <div class="stepper-wrapper">
                    {rentDates.map((val) => (
                      <div class="stepper-item completed">
                        <div class="step-counter">
                          <img src="/images/check.png" alt="" />
                        </div>
                        <div class="step-name">{_t("10Points")}</div>
                      </div>
                    ))}
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      {/* <div class="step-name">50 Points</div> */}
                    </div>
                  </div>

                  <div className="cards-wrapper">
                    {rentDates.map((val) => (
                      <div className="p-2">
                        <div className="date-card">
                          <p className="date-card-header">
                            {moment(val.processing_date).year()}
                          </p>
                          <div className="date-card-footer">
                            <p className="card-date">
                              {_t(moment(val.processing_date).format("MMM"))}
                              {/* {moment(val.processing_date).date()} */}
                            </p>
                            <p className="card-year"></p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="p-2">
                      <div className="date-card incomplete-date">
                        <p className="date-card-header">
                          {moment(upcomingDate).year()}
                        </p>
                        <div className="date-card-footer">
                          <p className="card-date">
                            {_t(moment(upcomingDate).format("MMM"))}
                          </p>
                          <p className="card-year">
                            {/* {moment().clone().add(1, "months").year()} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!showBig && (
            <div className="col-lg-6 padd-set-for-col">
              <div className="d-flex align-items-center gap-3 justify-content-start mobile-address-d-none">
                <img
                  className="card-content-image mobile-card-content"
                  src="/images/home.svg"
                  alt="home-icon"
                />
                <p className="address-p">
                  {user?.rental_data?.type == constants.RENTAL_INFO
                    ? `
                    ${rentalDetails?.street || ""} ${
                        rentalDetails?.street_number || ""
                      }, ${rentalDetails?.floor_number || ""} - ${
                        rentalDetails?.flat_number || ""
                      }
                    ${rentalDetails?.province || ""}, ${
                        rentalDetails?.postcode || ""
                      }
                  `
                    : `${rentalDetails?.property_id?.street || ""} ${
                        rentalDetails?.property_id?.street_number || ""
                      }, ${rentalDetails?.property_id?.floor_number || ""} - ${
                        rentalDetails?.property_id?.flat_number || ""
                      }
                    ${rentalDetails?.property_id?.province || ""}, ${
                        rentalDetails?.property_id?.postcode || ""
                      }`}
                </p>
              </div>
            </div>
          )}
        </div>
        {localStorage.getItem("usertype") == "tenant" && showBig && (
          <div
            class={`${
              paySectionOnTop ? "d-none" : ""
            } d-md-flex tile-design border-top mt-4 pt-3 border-bottom-0 pb-0 mb-0`}
          >
            <div class="tile-content-wrapper">
              <div class="tile-content">
                <h4 class="tile-heading">{_t("payYourMonthlyRent")}</h4>
                <p class="tile-desc">
                  {_t(
                    "payYourNextRentPaymentThroughSkorAndKeepEarningPointsWhileImprovingYourRentalHistory"
                  )}
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center gap-4 tile-btns-wrapper">
              <button class="incomplete-btn" onClick={navigateRevisitPayment}>
                {_t("payNow")}
              </button>

              {localStorage.getItem("usertype") == "tenant" && showBig && (
                <div className="d-flex d-md-none justify-content-center align-items-center">
                  <p
                    className="text-decoration-underline ms-3"
                    onClick={(e) => navigate("/tenant/review/rental")}
                  >
                    {_t("viewDetails")}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="32px"
                    viewBox="0 -960 960 960"
                    width="32px"
                    fill="#5f6368"
                  >
                    <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
                  </svg>
                </div>
              )}
              {localStorage.getItem("usertype") == "tenant" && showBig && (
                <button
                  class="btn btndarkgreen d-none d-md-block"
                  onClick={(e) => navigate("/tenant/review/rental")}
                >
                  {_t("viewAllDetails")}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default TenantRentalDetails;
