import { Col, Row } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";
import tickNew from "../../assets/img/tick-new.svg";
import { Link, useNavigate } from "react-router-dom";
import { haveValue } from "../../helper/common";
import { MdInfoOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../services/authWrapper";
import LandlordBlurApprove from "../Landlord/LandlordBlurApprove";

const ProfileInfoSection = (props) => {
  const {
    user,
    alpha3ToName,
    userKycInfo,
    setShowPhoneVerificationdModal,
    handleRemoveGuarantor,
    handleGurantorModal,
    showActionPrompts = false,
    sharersData = [],
    isLandlord = false,
    landlord_user = {},
  } = props;
  const { _t } = useTranslator();
  const navigate = useNavigate();

  //States
  const [flatMateModel, setFlatMateModel] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  //Handlers
  const handleClick = async (sharer) => {
    // Set loading state for this sharer
    setLoadingStates((prevState) => ({
      ...prevState,
      [sharer.sharerId]: true,
    }));

    if (sharer.sharerId) {
      // Navigate to profile
      goToProfile(sharer.sharerId);
    } else {
      try {
        // Send share profile email
        await sendShareProfileEmail({
          sharerName: sharer.name,
          sharerEmail: sharer.email,
          tenantId: user._id,
          landlordId: localStorage.getItem("saveduserid"),
        });
        toast.success(_t("Invitation link sent successfully"));
      } catch (error) {
        toast.error(error.message);
        console.error("Error sending email:", error);
      }
    }

    // Reset the loading state for this sharer once the operation is complete
    setLoadingStates((prevState) => ({
      ...prevState,
      [sharer.sharerId]: false,
    }));
  };

  const sendShareProfileEmail = async (data) => {
    await axiosInstance
      .post(
        `${process.env.REACT_APP_SERVER_URL}/shared-profile/sendShareProfileEmail`,
        {
          sharerName: data.sharerName,
          sharerEmail: data.sharerEmail,
          tenantId: data.tenantId,
          landlordId: data.landlordId,
        },
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const goToProfile = (sharerId) => {
    navigate(`/profile-property?sharedprofileid=${sharerId}`);
  };

  return (
    <>
      <Col
        xl={6}
        className={`mb-3 mobile-profile ${
          landlord_user?.account_status != "approved" && "blur-approved"
        }`}
      >
        <div className="score-personal-info border-pd accordion-tile position-relative">
          <h4>{_t("personalInfo")}</h4>
          <Row className="mx-0 mobile-perdonal-info">
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("name")}:</h4>
                {user?.onfido_user_kyc?.onfido_doc_data?.first_name ? (
                  <h3>
                    {`${user?.onfido_user_kyc?.onfido_doc_data?.first_name} ${user?.onfido_user_kyc?.onfido_doc_data?.last_name}`}{" "}
                    <img src={tickNew} alt="" />
                  </h3>
                ) : user?.fname ? (
                  <h3>{`${user?.fname} ${user?.lname}`}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
                <h3></h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("nationality")}:</h4>
                {user?.nationality ||
                haveValue(
                  user?.onfido_user_kyc?.onfido_doc_data?.nationality
                ) ? (
                  <h3>
                    {haveValue(
                      user?.onfido_user_kyc?.onfido_doc_data?.nationality
                    ) ? (
                      <>
                        {_t(
                          alpha3ToName(
                            user?.onfido_user_kyc?.onfido_doc_data?.nationality
                          ),
                          "fixed"
                        )}{" "}
                        <img src={tickNew} alt="" />
                      </>
                    ) : (
                      _t(user?.nationality, "fixed")
                    )}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("passportID")}:</h4>
                {user?.passport_id ||
                (haveValue(userKycInfo?.onfido_doc_data?.document_number) &&
                  userKycInfo?.onfido_doc_data?.document_type == "passport") ? (
                  <h3>
                    {haveValue(userKycInfo?.onfido_doc_data?.document_number) &&
                    userKycInfo?.onfido_doc_data?.document_type ==
                      "passport" ? (
                      <>
                        {userKycInfo?.onfido_doc_data?.document_number}
                        <img src={tickNew} alt="" />
                      </>
                    ) : (
                      user?.passport_id
                    )}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("dniNie")}:</h4>
                {user?.dni_nie ||
                haveValue(userKycInfo?.onfido_doc_data?.personal_number) ? (
                  <h3>
                    {haveValue(userKycInfo?.onfido_doc_data?.personal_number) &&
                    userKycInfo?.onfido_doc_data?.document_issuing_country ==
                      "ESP" &&
                    userKycInfo?.onfido_doc_data?.document_type !=
                      "passport" ? (
                      <>
                        {" "}
                        {userKycInfo?.onfido_doc_data?.personal_number}
                        <img src={tickNew} alt="" />
                      </>
                    ) : (
                      user?.dni_nie
                    )}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("phoneNumber")}:</h4>
                {user?.phone_number ? (
                  <>
                    <h3 className="d-flex">
                      +{`${user?.phone_number}`}
                      {user?.phone_number_verified && (
                        <img src={tickNew} alt="" />
                      )}
                      {user?.phone_number_verified !== true &&
                        showActionPrompts && (
                          <ul className="infodorpdown summarydropdum">
                            <li>
                              <button className="iconinfo">
                                <MdInfoOutline />
                              </button>
                            </li>
                            <ul className="tooltip">
                              <li>
                                <p>
                                  {_t("youHaveNotVerifiedYourPhoneNumberYet")}
                                </p>
                                <button
                                  className="btnupdate"
                                  onClick={() => {
                                    setShowPhoneVerificationdModal(true);
                                  }}
                                >
                                  {_t("verifyNow")}
                                </button>
                              </li>
                            </ul>
                          </ul>
                        )}
                    </h3>
                  </>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("flatmates")}:</h4>
                {haveValue(user?.sharers) ? (
                  <h3 className="setNoInfoColor text-start">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) =>
                          navigate("/edit-profile/tenant#add_flatmate_id")
                        }
                      >
                        {user?.sharers.length} {_t("flatmates")}
                      </a>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        style={{ color: "inherit" }}
                        onClick={() => {
                          setFlatMateModel(true);
                        }}
                      >
                        {user?.sharers.length} {_t("flatmates")}
                      </button>
                    )}{" "}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addFlatmates")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="Score-content-list">
                <h4>{_t("email")}:</h4>
                {user?.email ? (
                  <div className="email_flex">
                    <h3>{user?.email}</h3>
                    <img src={tickNew} alt="" />
                  </div>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        +{_t("addInfo")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("noInfo")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            {(user?.have_gurantor == "true" &&
              haveValue(user?.gurantor_name) &&
              haveValue(user?.gurantor_email)) ||
            showActionPrompts ? (
              <div className="gurantor_box">
                <h4>{_t("guarantorDetails")}</h4>

                <Row>
                  {user?.have_gurantor == "true" &&
                  haveValue(user?.gurantor_name) &&
                  haveValue(user?.gurantor_email) ? (
                    <>
                      <Col md={6}>
                        <div className="Score-content-list">
                          <h4 className="d-flex align-items-center gap-1">
                            {_t("nameOfGuarantor")}:
                            {!haveValue(user?.gurantor_id) &&
                              haveValue(user?.gurantor_email) &&
                              showActionPrompts && (
                                <label className="">
                                  <ul className="infodorpdown summarydropdum position-relative">
                                    <li>
                                      <button className="iconinfo guarantor_tooltip">
                                        <MdInfoOutline />
                                      </button>{" "}
                                    </li>
                                    <ul className="tooltip">
                                      <li>
                                        {_t(
                                          "waitingForGuarantorToAcceptYourRequest"
                                        )}
                                        .
                                      </li>
                                    </ul>
                                  </ul>
                                </label>
                              )}
                          </h4>

                          {user?.gurantor_name ? (
                            <>
                              <h3>{user?.gurantor_name}</h3>
                            </>
                          ) : (
                            <h3>{_t("noGuarantor")}</h3>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="Score-content-list">
                          <h4 className="d-flex align-items-center">
                            {_t("guarantorEmail")}:
                          </h4>
                          {user?.gurantor_email ? (
                            <h3>{user?.gurantor_email} </h3>
                          ) : (
                            <h3 className="setNoInfoColor">
                              {showActionPrompts ? (
                                <a
                                  href={undefined}
                                  onClick={(e) =>
                                    navigate("/edit-profile/tenant")
                                  }
                                >
                                  +{_t("addInfo")}
                                </a>
                              ) : (
                                <p href={undefined}>{_t("noInfo")}</p>
                              )}
                            </h3>
                          )}
                        </div>
                      </Col>
                    </>
                  ) : showActionPrompts ? (
                    haveValue(user?.gurantor_for) &&
                    haveValue(user?.gurantor_for?._id) ? (
                      <Col md={6}>
                        <div className="Score-content-list">
                          <h4>{_t("guarantorOf")}:</h4>
                          {user?.gurantor_for?.fname ? (
                            <h3>
                              {user?.gurantor_for?.fname}{" "}
                              {user?.gurantor_for?.lname}
                              <a
                                href={undefined}
                                onClick={handleRemoveGuarantor}
                              >
                                <svg
                                  width="7"
                                  height="13"
                                  viewBox="0 0 7 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 12L6 6.5L1 0.999999"
                                    stroke="#EF7833"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </a>
                            </h3>
                          ) : (
                            <h3 className="setNoInfoColor">
                              <a
                                href={undefined}
                                onClick={(e) =>
                                  navigate("/edit-profile/tenant")
                                }
                              >
                                +{_t("addInfo")}
                              </a>
                            </h3>
                          )}
                        </div>
                      </Col>
                    ) : (
                      <Col md={6}>
                        <a
                          href={undefined}
                          className="setNoInfoColor"
                          onClick={handleGurantorModal}
                        >
                          +{_t("addGuarantor")}
                        </a>
                      </Col>
                    )
                  ) : null}
                </Row>
              </div>
            ) : null}
          </Row>
          {isLandlord && landlord_user?.account_status != "approved" && (
            <LandlordBlurApprove landlord_user={landlord_user} />
          )}
        </div>
      </Col>

      <Modal
        show={flatMateModel}
        className="metermodal modal-xl"
        centered
        onHide={(e) => {
          setFlatMateModel(false);
        }}
      >
        <Modal.Body>
          <div className="d-flex justify-content-between assigned_details_modal">
            <div className="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.7068 0.29279L19.7051 9.29291C20.335 9.92291 19.889 10.9999 18.9982 10.9999H17.9984V16.9999C17.9984 17.7956 17.6824 18.5587 17.1199 19.1213C16.5574 19.6839 15.7945 20 14.9989 20H13.9992V12.9999C13.9992 12.2347 13.7069 11.4984 13.182 10.9416C12.6572 10.3849 11.9394 10.0498 11.1756 10.0049L10.9997 9.99988H9.00005C8.20458 9.99988 7.44164 10.3159 6.87917 10.8786C6.31664 11.4412 6.00064 12.2042 6.00064 12.9999V20H5.00082C4.20531 20 3.4424 19.6839 2.87989 19.1213C2.31739 18.5587 2.00137 17.7956 2.00137 16.9999V10.9999H1.00156C0.111727 10.9999 -0.33519 9.92291 0.294693 9.29291L9.29299 0.29279C9.48052 0.105316 9.73476 0 9.99987 0C10.265 0 10.5193 0.105316 10.7068 0.29279ZM10.9997 11.9999C11.2649 11.9999 11.5192 12.1053 11.7067 12.2928C11.8942 12.4804 11.9995 12.7347 11.9995 12.9999V20H8.00029V12.9999C8.00029 12.755 8.09017 12.5186 8.25293 12.3356C8.41564 12.1525 8.63988 12.0356 8.88311 12.0069L9.00005 11.9999H10.9997Z"
                  fill="#253837"
                />
              </svg>
              <p className="assigned_address">{_t("flatmates")}</p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setFlatMateModel(false);
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7734 1.32064C16.8453 1.24881 16.9022 1.16352 16.9411 1.06966C16.98 0.975805 17 0.875207 17 0.773615C17 0.672022 16.98 0.571424 16.9411 0.477565C16.9022 0.383706 16.8453 0.298423 16.7734 0.226586C16.7016 0.15475 16.6163 0.0977657 16.5224 0.058888C16.4286 0.0200102 16.328 0 16.2264 0C16.1248 0 16.0242 0.0200102 15.9303 0.058888C15.8365 0.0977657 15.7512 0.15475 15.6794 0.226586L8.5 7.40749L1.32064 0.226586C1.24881 0.15475 1.16352 0.0977657 1.06966 0.058888C0.975805 0.0200102 0.875207 7.56923e-10 0.773615 0C0.672022 -7.56923e-10 0.571424 0.0200102 0.477565 0.058888C0.383706 0.0977657 0.298423 0.15475 0.226586 0.226586C0.15475 0.298423 0.0977657 0.383706 0.058888 0.477565C0.0200102 0.571424 -7.56923e-10 0.672022 0 0.773615C7.56923e-10 0.875207 0.0200102 0.975805 0.058888 1.06966C0.0977657 1.16352 0.15475 1.24881 0.226586 1.32064L7.40749 8.5L0.226586 15.6794C0.0815054 15.8244 0 16.0212 0 16.2264C0 16.4316 0.0815054 16.6283 0.226586 16.7734C0.371667 16.9185 0.568439 17 0.773615 17C0.97879 17 1.17556 16.9185 1.32064 16.7734L8.5 9.59251L15.6794 16.7734C15.8244 16.9185 16.0212 17 16.2264 17C16.4316 17 16.6283 16.9185 16.7734 16.7734C16.9185 16.6283 17 16.4316 17 16.2264C17 16.0212 16.9185 15.8244 16.7734 15.6794L9.59251 8.5L16.7734 1.32064Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="assigned_details_body">
            <ul>
              {sharersData.map((sharer) => (
                <li
                  key={sharer.sharerId}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                    alignItems: "center",
                  }}
                >
                  <div class="linked_li_div">
                    <p class="linked_li_name">{sharer.name}</p>
                    {sharer.sharerId && (
                      <p class="linked_li_score">
                        {_t("Score")}: {sharer.score}
                      </p>
                    )}
                  </div>
                  <button
                    type="button"
                    style={{ flex: "0 0 40%" }}
                    className={`btn py-2  ${
                      sharer.sharerId ? "btngreen" : "btnoutline cancel-btn"
                    }`}
                    onClick={() => handleClick(sharer)}
                    disabled={loadingStates[sharer.sharerId]}
                  >
                    {loadingStates[sharer.sharerId]
                      ? `${_t("Sending")}...`
                      : sharer.sharerId
                      ? _t("viewProfile")
                      : _t("invite")}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileInfoSection;
