import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import AuthContext from "../../../context/auth/AuthContext";
import TenantContext from "../../../context/tenant/TenantContext";
import backicon from "../../../assets/img/back.png";
import { useSnackbar } from "material-ui-snackbar-provider";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserScoreProfile from "../../common/userProfile/UserScoreProfile";
import useTranslator from "../../../hooks/useTranslator";
import axiosInstance from "../../../services/authWrapper";

const ProfileSharing = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  let { tenantid } = useParams();
  console.log(tenantid);

  const authContext = useContext(AuthContext);
  const { user, userType } = authContext;

  const tenantContext = useContext(TenantContext);
  const { loadSharedProfiles } = tenantContext;

  const [loading, setLoading] = useState(true);
  const [sharedProfile, setSharedProfile] = useState({});
  const [profileid, setSharedProfileid] = useState("");

  useEffect(() => {
    if (tenantid) {
      loadsingleUser(tenantid);
      let landlordid = localStorage.getItem("saveduserid");
      shareProfile(tenantid, landlordid, "null", "basic", {
        shareType: "tLink",
      });
    }
  }, []);

  const shareProfile = async (
    tenantid,
    landlordid,
    sharedprofileid,
    profiletype,
    propertyPayload
  ) => {
    console.log(tenantid, landlordid, profiletype, propertyPayload);
    await axiosInstance
      .post(
        `${process.env.REACT_APP_SERVER_URL}/tenants/send-profile/${tenantid}/${landlordid}/${sharedprofileid}?type=${profiletype}`,
        propertyPayload,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res.data);
        let serverResponse = res;
        if (serverResponse.data.status == "success") {
          setSharedProfileid(
            serverResponse.data.notification.shared_profile_id
          );
          snackbar.showMessage(_t("tenantProfileReceivedCheckNotification"));
        }
        loadSharedProfiles(tenantid, "full");
        loadSharedProfiles(tenantid, "basic");
      })
      .catch((err) => {
        let err_res = err;
        console.log(err_res);
        toast.error("Error", err.response);
      });
  };

  const loadsingleUser = async (tenantid) => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/get-single/${tenantid}?type=tenant`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        setSharedProfile(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const sendLink = (tenantid) => {
    navigate(`/send-link?sharedprofileid=${profileid}&tenantid=${tenantid}`);
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="tabs-item d-block tabs-wapper2">
        <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
          <div>
            <Link to="/home">
              <img src={backicon} alt="backbtn" />
            </Link>
          </div>
          <div></div>
        </div>
        <UserScoreProfile name={"hello"} scoreProfile={sharedProfile} />
        <div className="Score-right">
          <div className="row">
            <div>
              {userType == "landlord" && (
                <button
                  className="btn-fill2"
                  onClick={() => sendLink(sharedProfile?._id)}
                >
                  {_t("sendPropertyLink")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSharing;
