import useTranslator from "../../../hooks/useTranslator";

const UserTypeCheckbox = ({ value, checked, onChange, imgSrc, label }) => (
    <div className="uilabel">
        <input
            className="form-check-input"
            type="checkbox"
            value={value}
            checked={checked}
            onChange={onChange}
            id={label}
        />
        <label className="form-check-label img-diff" htmlFor={label}>
            <div className="imgbar">
                <img src={imgSrc} alt="icon" />
            </div>
            <div className="contentbar">
                <h5>{label}</h5>
            </div>
        </label>
    </div>
);

const SelectUserTypeScreen = ({ checkedTenant, checkedLandlord, handleUsertype, setCurrentScreen }) => {
    const { _t } = useTranslator();
    return (
        <>
            <div className="checkboxbar">
                <div className="inlinecheckbar">
                    <UserTypeCheckbox
                        value="tenant"
                        checked={checkedTenant}
                        onChange={handleUsertype}
                        imgSrc="/images/t1.svg"
                        label={_t("tenant")}
                    />
                    <UserTypeCheckbox
                        value="landlord"
                        checked={checkedLandlord}
                        onChange={handleUsertype}
                        imgSrc="/images/t2.svg"
                        label={_t("landlord")}
                    />
                </div>
            </div>
            <div className="btngroup">
                <button type="button" className="btn btnorange" onClick={() => setCurrentScreen("selectUserEmail")}> {_t("Continue")}</button>
            </div>
        </>
    )
}

export default SelectUserTypeScreen;