import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import { auth } from '../../services/firebase.service';
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import useTranslator from '../../hooks/useTranslator';
import OTPInput from 'otp-input-react';
import Form from 'react-bootstrap/Form';
import useCountdown from '../../hooks/useCountdown';
import { haveValue } from '../../helper/common';

const OtpVerification = (props) => {
  const { _t } = useTranslator();
  const { phone = null, onSubmit = () => { }, onCancel = () => { } } = props;

  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [verificationId, setVerificationId] = useState(null);
  const [otp, setOtp] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { seconds, startCountdown, resetCountdown } = useCountdown(30);

  useEffect(() => {
    // resetRecaptcha();
    // return () => {
    //     resetRecaptcha();
    // }
  }, []);

  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = null;
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        { size: 'invisible' }
      );
      window.recaptchaVerifier = recaptchaVerifier;
    }
  };

  const resetRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = null;
    document.getElementById('recaptcha-container').innerHTML = '';
  };

  const requestOtp = async () => {
    // Check if phone number is valid
    if (!haveValue(phoneNumber) || phoneNumber.length < 10) {
      toast.error(_t('pleaseEnterAValidPhoneNumber'));
      return;
    }

    setDisabled(true);

    try {
      // Set the language code
      auth.languageCode =
        localStorage.getItem('i18nextLng') === 'es' ? 'es' : 'en-us';
      generateRecaptcha();

      const recaptchaVerifier = window.recaptchaVerifier;
      const result = await signInWithPhoneNumber(
        auth,
        `+${phoneNumber}`,
        recaptchaVerifier
      );
      setVerificationId(result.verificationId);
      toast.success(_t('otpSentSuccessfully'));
      resetCountdown();
      startCountdown();
    } catch (error) {
      console.log(error);
      toast.error(_t('invalidPhoneNumber'));
    } finally {
      setDisabled(false);
    }
  };

  const verifyOtp = async () => {
    try {
      if (haveValue(otp) && otp.length === 6) {
        setDisabled(true);
        const credentails = PhoneAuthProvider.credential(verificationId, otp);
        await signInWithCredential(auth, credentails);
        onSubmit(phoneNumber);
      } else {
        throw new Error(_t('invalidOtp'));
      }
    } catch (error) {
      toast.error(_t('invalidOtp'));
    } finally {
      setDisabled(false);
    }
  };

  const otpBackHandler = () => {
    setOtp('');
    resetCountdown();
    setVerificationId(null);
  };

  const otpinputChange = (e) => {
    setOtp(e);
  };

  return (
    <>
      {!verificationId && (
        <>
          <h3 className="step-name">{_t('verifyPhoneNumber')}</h3>
          <div className="forminput row100 auth-form phonenumber-input special-gap">
            <label className="forminput">
              {_t('phoneNumber')}
              <span className="mandatory_span">*</span>
            </label>
            <PhoneInput
              className="phone_input"
              international
              country="es"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>

          <div class="d-flex align-items-center gap-3 step-btns-group">
            <button
              class="btn btnoutline step-btn"
              type="button"
              onClick={onCancel}
              disabled={disabled}
            >
              {_t('goBack')}
            </button>
            <button
              class="btn btnorange step-btn"
              onClick={requestOtp}
              disabled={disabled}
            >
              {_t('requestOtp')}
            </button>
          </div>
          <button
            class="btn skip-btn"
            type="button"
            onClick={() => onSubmit(null)}
            disabled={disabled}
          >
            {_t('skipForNow')}
          </button>
        </>
      )}
      {verificationId && (
        <>
          <h3 className="step-name with-less-margin">
            {_t('otpVerification')}
          </h3>
          <p className="text-center otp-step-desc">
            {_t('pleaseEnterOtpSentOnYourPhoneNumber')}
          </p>
          <Form className="loginform otp-Verification">
            <OTPInput
              className="forminput"
              value={otp}
              onChange={otpinputChange}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
            />
          </Form>
          {seconds > 0 && (
            <p className="timer otp-timer">
              {_t('resendCode')} <span className="timer-count">{seconds}s</span>
            </p>
          )}
          {seconds === 0 && (
            <a
              href="javsacript:void(0)"
              class="orange_text resend-otp-text text-center"
              onClick={requestOtp}
              disabled={disabled}
            >
              {_t('requestOtp')}
            </a>
          )}
          <div class="d-flex align-items-center gap-3 step-btns-group">
            <button
              class="btn btnoutline step-btn"
              type="button"
              onClick={otpBackHandler}
              disabled={disabled}
            >
              {_t('goBack')}
            </button>
            <button
              class="btn btnorange step-btn"
              onClick={verifyOtp}
              disabled={disabled}
            >
              {_t('verifyOtp')}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default OtpVerification;
