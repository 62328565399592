import { Col, Row, Spinner } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";
import tickNew from "../../assets/img/tick-new.svg";
import crossNew from "../../assets/img/cross-new.svg";
import exclamation from "../../assets/img/exclamation.svg";
import { MdInfoOutline, MdOutlineVerified } from "react-icons/md";
import { convertCurrencyFormat, haveValue } from "../../helper/common";
import { useContext, useState } from "react";
import TenantContext from "../../context/tenant/TenantContext";
import { init } from "onfido-sdk-ui";
import AuthContext from "../../context/auth/AuthContext";
import { BsBank2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Slider, styled } from "@mui/material";
import LandLordContext from "../../context/landlord/LandLordContext";
import QRCode from "react-qr-code";
import { padding } from "@mui/system";
import axiosInstance from "../../services/authWrapper";
import LandlordBlurApprove from "../Landlord/LandlordBlurApprove";

const SummarySection = (props) => {
  const {
    user,
    showActionPrompts = false,
    ident_all,
    recordedSalary,
    bankhandleShow = (e) => {
      console.log("Only available for Tenant");
    },
    haveGuarantor,
    rentDoc = false,
    setRentDoc = (e) => console.log("ONLY AVAILABLE FOR LANDLORD"),
    rentPercentage = 0,
    setRentPercentage = (e) => console.log("ONLY AVAILABLE FOR LANDLORD"),
    rentValFrom = null,
    isProfilePreview = false,
    isLandlord = false,
    landlord_user = {},
  } = props;
  const queryParameters = new URLSearchParams(window.location.search);
  const tenantContext = useContext(TenantContext);
  const authContext = useContext(AuthContext);
  const landLordContext = useContext(LandLordContext);
  const { saveRentPercentage } = landLordContext;
  const { loadUser, userType, checkIeDetails } = authContext;
  const { getKycDocs, onfidoKyc } = tenantContext;
  const { _t } = useTranslator();
  const marks = [
    {
      value: 1,
      label: "1%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const [showSpinner, setShowSpinner] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const handleViewDocument = async () => {
    setShowSpinner(true);
    let userid = user?._id;
    getKycDocs(userid)
      .then((res) => {
        const nodeJSBuffer = res.data.images.data;
        const uint8Array = new Uint8Array(nodeJSBuffer);

        // Convert Uint8Array to ArrayBuffer
        const arrayBuffer = uint8Array.buffer;

        // Create a Blob
        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });

        // Create a download link
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "documents.zip";

        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
        setShowSpinner(false);
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log(err);
      });
  };

  const initiateOnfido = () => {
    setButtonDisable(true);
    axiosInstance
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        //console.log("ONFIDO WITH WORKFLOW= ", res.data);
        setButtonDisable(false);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        //console.log("Error Onfido = ", err);
        setButtonDisable(false);
      });
  };

  const initiateKyc = (token, workFlowRunId) => {
    //console.log("On Fido Token = ", token);
    setButtonDisable(true);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      language: localStorage.getItem("i18nextLng") == "es" ? "es" : "en_US",
      containerId: "onfido-mount",
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        console.log("everything is complete");

        setButtonDisable(false);
        saveOnfidoKycResponse(data);
        loadUser(userType);
      },
      onError: function (err) {
        setButtonDisable(false);
      },
      onUserExit: function (userExitCode) {
        console.log("user exitted", userExitCode);
        setButtonDisable(false);
      },
      onModalRequestClose: function () {
        // Update options with the state of the modal
        onfido.setOptions({ isModalOpen: false });
        setButtonDisable(false);
        console.log("modal closed of onfido");
      },
      //steps: ['welcome', 'poa', 'document', 'face', 'complete'],  //poa-proof of address
      steps: ["welcome", "document", "face", "complete"],
    });
  };

  const saveOnfidoKycResponse = (kycData) => {
    onfidoKyc(user?._id, kycData)
      .then((res) => {})
      .catch((err) => {});
  };

  const RentSlider = styled(Slider)({
    color: "#A3EB64",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "7px solid #253837",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&::before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 9,
      background: "unset",
      padding: 0,
      width: 38,
      height: 38,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&::before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  const calculateRentAmount = (e, val) => {
    setRentPercentage(val);
    const data = {
      shared_profile_id: queryParameters.get("sharedprofileid"),
      rent_percentage: val,
    };
    saveRentPercentage(data);
  };

  return (
    <>
      <Col xl={6} className="mb-3 mobile-profile ">
        <div className="score-summary border-pd prime-summary-sec accordion-tile">
          <div className={isProfilePreview && "row"}>
            <h4 className="">
              {isProfilePreview ? _t("summaryAndProfileQR") : _t("summary")}
            </h4>
            <div
              className={`summary-checkes ${
                isProfilePreview && "col-12 col-md-6"
              }`}
            >
              {isProfilePreview && (
                <h3 className="summary-sub-heading">{_t("yourSummary")}:</h3>
              )}
              <label className="rounded-check summaryinfobar padding10">
                {user?.profile_completed == true ? (
                  <img src={tickNew} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("completedProfile")}
              </label>
              <label
                className={`rounded-check summaryinfobar flexbarinner no-wrap-on-mobile ${
                  (isProfilePreview ||
                    (!isProfilePreview && !showActionPrompts)) &&
                  "padding10"
                }`}
              >
                <div class="mobile-identification summaryinfobar">
                  {/* --------------------new responsive css (heena)----------------- */}
                  {ident_all == true ? (
                    <img src={tickNew} alt="" />
                  ) : user?.onfido_user_kyc?.onfido_doc &&
                    user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                      "declined" ? (
                    <img src={exclamation} alt="" />
                  ) : (
                    <img src={crossNew} alt="" />
                  )}
                  {_t("verifiedIdentification")}
                  {/* --------------------new responsive css (heena)-----------------  */}
                  {user?.onfido_kyc_success == false &&
                    user?.onfido_user_kyc?.onfido_doc &&
                    user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                      "declined" &&
                    showActionPrompts &&
                    !isProfilePreview && (
                      <ul className="infodorpdown summarydropdum">
                        <li>
                          <button className="iconinfo">
                            <MdInfoOutline />
                          </button>{" "}
                        </li>
                        <ul className="tooltip">
                          <p>{_t("verificationInProcess")}</p>
                        </ul>
                      </ul>
                    )}
                  {user?.onfido_kyc_success == false &&
                    !haveValue(user?.onfido_user_kyc?.onfido_doc) &&
                    showActionPrompts &&
                    !isProfilePreview && (
                      <ul className="infodorpdown summarydropdum">
                        <li>
                          <button className="iconinfo">
                            <MdInfoOutline />
                          </button>{" "}
                        </li>
                        <ul className="tooltip">
                          <li>
                            {_t(
                              "youHaveNotCompletedYourDigitalVerificationYet"
                            )}
                            .
                          </li>
                        </ul>
                      </ul>
                    )}
                </div>
                {user?.onfido_user_kyc?.onfido_doc &&
                  user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                    "declined" &&
                  !isProfilePreview && (
                    <Col
                      md={6}
                      className="d-flex gap-2 align-items-center justify-content-end"
                    >
                      {!isProfilePreview &&
                        (showActionPrompts ? (
                          <>
                            <button
                              type="button"
                              href={undefined}
                              onClick={handleViewDocument}
                              className="rounded-check summaryinfobar btn btngreen view_doc_btn hide-for-mob"
                              id="viewDocumentBtn "
                            >
                              {_t("viewDocument")}
                            </button>
                            <a
                              href={undefined}
                              onClick={handleViewDocument}
                              id="viewDocumentBtn"
                              className=" hide-for-desktop"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M9 13.5L3.375 7.875L4.95 6.24375L7.875 9.16875V0H10.125V9.16875L13.05 6.24375L14.625 7.875L9 13.5ZM2.25 18C1.63125 18 1.10175 17.7799 0.6615 17.3396C0.22125 16.8994 0.00075 16.3695 0 15.75V12.375H2.25V15.75H15.75V12.375H18V15.75C18 16.3687 17.7799 16.8986 17.3396 17.3396C16.8994 17.7806 16.3695 18.0007 15.75 18H2.25Z"
                                  fill="#EF7833"
                                />
                              </svg>
                            </a>
                          </>
                        ) : (
                          <a
                            href={undefined}
                            onClick={handleViewDocument}
                            id="viewDocumentBtn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M9 13.5L3.375 7.875L4.95 6.24375L7.875 9.16875V0H10.125V9.16875L13.05 6.24375L14.625 7.875L9 13.5ZM2.25 18C1.63125 18 1.10175 17.7799 0.6615 17.3396C0.22125 16.8994 0.00075 16.3695 0 15.75V12.375H2.25V15.75H15.75V12.375H18V15.75C18 16.3687 17.7799 16.8986 17.3396 17.3396C16.8994 17.7806 16.3695 18.0007 15.75 18H2.25Z"
                                fill="#EF7833"
                              />
                            </svg>
                          </a>
                        ))}
                      {showSpinner && (
                        <Spinner className="view_document_spinner" />
                      )}
                    </Col>
                  )}
                {(!haveValue(user?.onfido_kyc_success) ||
                  user?.onfido_kyc_success == false) &&
                !haveValue(user?.onfido_user_kyc?.onfido_doc) &&
                showActionPrompts &&
                !isProfilePreview ? (
                  <Col
                    md={6}
                    className="d-flex gap-2 align-items-center justify-content-end"
                  >
                    <button
                      className="btn btnorange w-100 view_doc_btn"
                      type="button"
                      disabled={buttonDisable}
                      onClick={initiateOnfido}
                      id="verify_now_btn"
                    >
                      {_t("verifyNow")}
                      <MdOutlineVerified />
                    </button>
                    {showSpinner && (
                      <Spinner className="view_document_spinner" />
                    )}
                  </Col>
                ) : (
                  <></>
                )}
              </label>
              <label className="rounded-check summaryinfobar padding10">
                {haveValue(user?.gurantor_id) ? (
                  haveValue(user?.gurantor_id?.paymentScores) ? (
                    <img src={tickNew} alt="" />
                  ) : (
                    <img src={crossNew} alt="" />
                  )
                ) : haveValue(user?.paymentScores) ? (
                  <img src={tickNew} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("verifiedBankAccount")}
              </label>
              <label className="rounded-check summaryinfobar padding10">
                {recordedSalary?.matched >= 80 ? (
                  <img src={tickNew} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("verifiedIncome")}
                {recordedSalary?.matched < 80 && !isProfilePreview ? (
                  <ul className="infodorpdown summarydropdum">
                    <li>
                      <button className="iconinfo">
                        <MdInfoOutline />
                      </button>{" "}
                    </li>
                    <ul className="tooltip">
                      <li>
                        <p>
                          {_t("income")} {recordedSalary?.matched}{" "}
                          {_t(
                            "percentMatchWithTheAnnualIncomeRecordedFromBankAccount"
                          )}
                        </p>
                        {showActionPrompts && (
                          <button
                            className="btnupdate"
                            onClick={bankhandleShow}
                          >
                            <BsBank2 />
                            {_t("updateBank")}
                          </button>
                        )}
                      </li>
                    </ul>
                  </ul>
                ) : (
                  (haveValue(user?.gurantor_id?.current_annual_income) ||
                    haveValue(user?.current_annual_income)) &&
                  !isProfilePreview && (
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>
                          <p>
                            {_t("income")} {recordedSalary?.matched}{" "}
                            {_t(
                              "percentMatchWithTheAnnualIncomeRecordedFromBankAccount"
                            )}
                          </p>
                        </li>
                      </ul>
                    </ul>
                  )
                )}
              </label>
              {user?.have_gurantor &&
                user?.have_gurantor == "true" &&
                (showActionPrompts ? (
                  <label className="rounded-check summaryinfobar padding10">
                    {haveGuarantor == true || haveGuarantor == "true" ? (
                      <img src={tickNew} alt="" />
                    ) : (
                      <img src={crossNew} alt="" />
                    )}
                    {_t("guarantor")}
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <p>
                          {haveValue(user?.gurantor_id) &&
                          haveValue(user?.gurantor_id?.paymentScores)
                            ? _t("theScoreYouSeeIsOfTheGuarantor")
                            : showActionPrompts
                            ? _t(
                                "guarantorAddedTheirScoreWillBeVisibleAfterBankDetailsAreProvided"
                              )
                            : null}
                        </p>
                      </ul>
                    </ul>
                  </label>
                ) : (
                  haveValue(user?.gurantor_id) &&
                  haveValue(user?.gurantor_id?.paymentScores) && (
                    <label className="rounded-check summaryinfobar padding10">
                      {haveGuarantor == true || haveGuarantor == "true" ? (
                        <img src={tickNew} alt="" />
                      ) : (
                        <img src={crossNew} alt="" />
                      )}
                      {_t("guarantor")}
                      <ul className="infodorpdown summarydropdum">
                        <li>
                          <button className="iconinfo">
                            <MdInfoOutline />
                          </button>{" "}
                        </li>
                        <ul className="tooltip">
                          <p>{_t("theScoreYouSeeIsOfTheGuarantor")}</p>
                        </ul>
                      </ul>
                    </label>
                  )
                ))}
              {haveValue(user?.ie_details_id) && user?.is_university && (
                <label className="rounded-check summaryinfobar padding10">
                  {checkIeDetails(user) ? (
                    <img src={tickNew} alt="" />
                  ) : (
                    <img src={crossNew} alt="" />
                  )}
                  {_t("verified")}
                  {checkIeDetails(user) &&
                    !showActionPrompts &&
                    !isProfilePreview && (
                      <ul className="infodorpdown summarydropdum">
                        <li>
                          <button className="iconinfo">
                            <MdInfoOutline />
                          </button>{" "}
                        </li>
                        <ul className="tooltip">
                          <li>
                            <p>
                              {_t(
                                "thisTenantHasBeenVerifiedAsAnIEStudentAndAssignedAnAutomaticSkorTheSkorWillChangeWhenConnectingTheBankAccoundAndOrAddingAGuarantor"
                              )}
                            </p>
                          </li>
                        </ul>
                      </ul>
                    )}
                  <img
                    src="/images/ie_logo.svg"
                    /* --------------------new responsive css (heena)----------------- */
                    height="2.20vw"
                    width="2.20vw"
                    alt=""
                  />
                </label>
              )}

              {(haveValue(user?.current_annual_income) ||
                haveValue(user?.itr_amount) ||
                recordedSalary?.salary != 0) &&
                !showActionPrompts &&
                localStorage.getItem("usertype") == "landlord" && (
                  <>
                    <div
                      className={`position-relative
                        ${
                          landlord_user?.account_status != "approved" &&
                          "blur-approved"
                        }
                        `}
                    >
                      {isLandlord &&
                      landlord_user?.account_status != "approved" ? (
                        <>
                          <div>
                            <h4 className="">{_t("maxRent")}</h4>
                            <select
                              name="rent_from_income"
                              id="rent_from_income"
                              className="custom-input"
                              onChange={(e) => setRentDoc(e.target.value)}
                              value={rentDoc}
                            >
                              {haveValue(user?.current_annual_income) && (
                                <option value="current_annual_income">
                                  {" "}
                                  {_t("annualIncome")}{" "}
                                </option>
                              )}
                              {haveValue(user?.itr_amount) && (
                                <option value="itr_amount">
                                  {_t("verifiedIncomeFromIRPF")}
                                </option>
                              )}
                              {haveValue(recordedSalary?.salary) &&
                                recordedSalary?.salary > 0 && (
                                  <option value="recordedSalary">
                                    {_t("verifiedAnnualIncome")}
                                  </option>
                                )}
                            </select>
                            <p className="percentage_salary">
                              {_t("percentageOfRentFromSalary")}
                            </p>
                            <div className="d-flex justify-content-between"></div>
                          </div>
                          <LandlordBlurApprove landlord_user={landlord_user} />
                        </>
                      ) : (
                        <div>
                          <h4 className="">{_t("maxRent")}</h4>
                          <select
                            name="rent_from_income"
                            id="rent_from_income"
                            className="custom-input"
                            onChange={(e) => setRentDoc(e.target.value)}
                            value={rentDoc}
                          >
                            {haveValue(user?.current_annual_income) && (
                              <option value="current_annual_income">
                                {" "}
                                {_t("annualIncome")}{" "}
                              </option>
                            )}
                            {haveValue(user?.itr_amount) && (
                              <option value="itr_amount">
                                {_t("verifiedIncomeFromIRPF")}
                              </option>
                            )}
                            {haveValue(recordedSalary?.salary) &&
                              recordedSalary?.salary > 0 && (
                                <option value="recordedSalary">
                                  {_t("verifiedAnnualIncome")}
                                </option>
                              )}
                          </select>
                          <p className="percentage_salary">
                            {_t("percentageOfRentFromSalary")}
                          </p>
                          <div className="d-flex justify-content-between property-percentage-box">
                            <div className="col-sm-7">
                              <RentSlider
                                className="rent-slider"
                                defaultValue={rentPercentage}
                                aria-label="Default"
                                onChangeCommitted={calculateRentAmount}
                                valueLabelDisplay="on"
                                valueLabelFormat={(value) => `${value}%`}
                                marks={marks}
                                min={1}
                                max={100}
                              />
                            </div>
                            <div className="col-sm-4 max_rent_div d-flex row">
                              <p className="max_rent_title col-sm-12">
                                {_t("maxRentAmount")}
                              </p>
                              <p className="col-sm-12 max_rent_val">
                                €
                                {haveValue(rentValFrom)
                                  ? convertCurrencyFormat(
                                      Math.floor(
                                        (rentValFrom * rentPercentage) / 1200
                                      )
                                    ) || 0
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
            </div>
            {isProfilePreview && (
              <div className="summary-checkes col-12 col-md-6">
                <div className="qr-img">
                  <h3 className="summary-sub-heading">{_t("profileQR")}:</h3>
                  <QRCode
                    id="qr_code_image"
                    value={`https://${process.env.REACT_APP_URL_NAME}/profile-request?sharedProfileLink=${user?._id}`}
                  />
                </div>
              </div>
            )}
          </div>
          {ident_all !== true &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status == "declined" &&
            showActionPrompts && (
              <div className="gurantor_box kyc_failed_div">
                <p className=" set_gap_2" style={{ textAlign: "center" }}>
                  <img src={crossNew} alt="" />
                  {_t("yourIdentityVerificationFailed")}
                </p>
                <Row>
                  <>
                    {haveValue(user?.onfido_user_kyc?.onfido_error) &&
                      haveValue(user?.onfido_user_kyc?.onfido_error[0]) && (
                        <Col md={6}>
                          <p
                            className="rounded-check"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {user?.onfido_user_kyc?.onfido_error[0]}
                          </p>
                        </Col>
                      )}
                    <Col>
                      <p
                        className="rounded-check"
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                      >
                        <Link to={undefined} onClick={initiateOnfido}>
                          {_t("clickHereToRetry")}
                        </Link>
                      </p>
                    </Col>
                  </>
                </Row>
              </div>
            )}
        </div>
      </Col>
    </>
  );
};

export default SummarySection;
