import OTPInput from "otp-input-react";
import Button from "react-bootstrap/Button";
import useTranslator from "../../../hooks/useTranslator";
import EmailField from "../../../components/Auth/EmailField";
import { haveValue } from "../../../helper/common";
const UserOtpScreen = ({
  userLogin,
  verifyOtpHandler,
  otp,
  otpinputChange,
  showResend,
  showTimer,
  sendOtpFun,
  isUserVerified,
  setCurrentScreen,
  presentUserData,
}) => {
  const { _t } = useTranslator();

  return (
    <>
      <div>
        <EmailField userLogin={userLogin} />
        <form
          className="loginform otp-Verification d-block"
          onSubmit={verifyOtpHandler}
        >
          <p className="otp-heading">{_t("enterOtp")}</p>
          <OTPInput
            className="forminput justify-content-center login-otp-box"
            value={otp}
            onChange={otpinputChange}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
          />
          <div className="w-100 otp-links">
            {!showResend && (
              <p className="timer mt-2 resend-otp-text">
                {" "}
                {_t("resendCode")}{" "}
                <span className="orange_text">{showTimer}s</span>
              </p>
            )}
            {showResend && (
              <a className="orange_text resend-otp-text" onClick={sendOtpFun}>
                {_t("resendOTP")}
              </a>
            )}

            <div className="btngroup ">
              <button type="submit" className="btn btnorange">
                {" "}
                {_t("Continue")}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isUserVerified && haveValue(presentUserData?.password) && (
        <>
          <div className="margin-top-12px">
            <span className="resend-otp-text">
              {_t("wantToLoginWithPassword")}?{" "}
            </span>
            <a
              href={undefined}
              onClick={() => setCurrentScreen("loginWithCredentials")}
              className="orange_text resend-otp-text"
            >
              {_t("clickHere")}
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default UserOtpScreen;
