import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SingleProperty from "./SingleProperty";
import { FaFilter } from "react-icons/fa";
import Spinner from "../../../components/Spinner";
import AuthContext from "../../../context/auth/AuthContext";
import TenantContext from "../../../context/tenant/TenantContext";
import LandLordContext from "../../../context/landlord/LandLordContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTranslator from "../../../hooks/useTranslator";
import { haveValue } from "../../../helper/common";
import searchicon from "../../../assets/img/s.png";

const Property = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const { isLoggedIn, logout, user, userType, loadUser } = authContext;

  const landLordContext = useContext(LandLordContext);
  const { landlord_loading, properties, loadProperties } = landLordContext;

  const [searchVal, setSearchVal] = useState("");
  const [filteredProperty, setFilteredProperty] = useState(properties);
  const [searchedProp, setSearchedProp] = useState(properties);
  const [filterVal, setFilterVals] = useState({
    stairway: [],
    floorNumber: [],
    letter: [],
  });
  const [filterCheck, setFilterCheck] = useState({
    stairway: "",
    floorNumber: "",
    letter: "",
    property: "available",
  });

  const loadData = () => {
    loadProperties(user?._id).then((res) => {
      if (haveValue(properties)) {
        const stairway = new Set();
        const floorNumber = new Set();
        const letter = new Set();
        properties.map((val) => {
          stairway.add(val?.staircase);
          floorNumber.add(val?.floor_number);
          letter.add(val?.flat_number);
        });
        setFilterVals({
          stairway: [...stairway],
          floorNumber: [...floorNumber],
          letter: [...letter],
        });
      }
    });
  };

  useEffect(() => {
    loadData();
    console.log("property use effect");
  }, []);

  useEffect(() => {
    setFilteredProperty(properties);
  }, [properties]);

  const addSearchVal = (e) => {
    const enteredVal = e.target.value;
    setSearchVal(enteredVal);
  };

  useEffect(() => {
    searchProperty();
  }, [searchVal, properties]);

  const searchProperty = () => {
    const enteredVal = searchVal;
    if (haveValue(properties)) {
      if (haveValue(enteredVal)) {
        const result = properties.filter((prop) => {
          const search_string =
            `${prop?.street} - ${prop?.street_number} ${prop?.postcode}`.replace(
              ",",
              ""
            );
          return (
            search_string.toLowerCase().includes(enteredVal.toLowerCase()) ||
            prop?.property_reference
              ?.toLowerCase()
              .includes(enteredVal.toLowerCase())
          );
        });
        const stairway = new Set();
        const floorNumber = new Set();
        const letter = new Set();
        result.map((val) => {
          stairway.add(val?.staircase);
          floorNumber.add(val?.floor_number);
          letter.add(val?.flat_number);
        });
        setFilterVals({
          stairway: [...stairway],
          floorNumber: [...floorNumber],
          letter: [...letter],
        });
        setFilterCheck({ stairway: "", floorNumber: "", letter: "" });
        setFilteredProperty(result);
        setSearchedProp(result);
      } else {
        const stairway = new Set();
        const floorNumber = new Set();
        const letter = new Set();
        properties.map((val) => {
          stairway.add(val?.staircase);
          floorNumber.add(val?.floor_number);
          letter.add(val?.flat_number);
        });

        setFilterVals({
          stairway: [...stairway],
          floorNumber: [...floorNumber],
          letter: [...letter],
        });
        setFilterCheck({
          stairway: "",
          floorNumber: "",
          letter: "",
          property: "available",
        });
        setFilteredProperty(properties);
        setSearchedProp(properties);
      }
    }
  };

  const handleFilterSelected = (type, e) => {
    setFilteredProperty(searchedProp);
    if (type == "stairway") {
      setFilterCheck((prev) => ({
        stairway: e.target.value,
        letter: "",
        floorNumber: "",
      }));
    }
    if (type == "floorNumber") {
      setFilterCheck((prev) => ({
        ...prev,
        letter: "",
        floorNumber: e.target.value,
      }));
    }
    if (type == "letter") {
      setFilterCheck((prev) => ({ ...prev, letter: e.target.value }));
    }

    if (type == "property") {
      const value = e.target.value;
      setFilterCheck((prev) => ({ ...prev, property: value }));
    }
    setFilterCheck((prev) => ({ ...prev, [type]: e.target.value }));
  };

  useEffect(() => {
    filterSearchedProp();
  }, [filterCheck]);

  const filterSearchedProp = () => {
    if (haveValue(filteredProperty)) {
      var temp = filteredProperty;
      if (haveValue(filterCheck.stairway)) {
        temp = temp.filter((val) => val?.staircase == filterCheck.stairway);

        const floorNumber = new Set();
        const letter = new Set();
        temp.map((val) => {
          floorNumber.add(val?.floor_number);
          letter.add(val?.flat_number);
        });

        setFilterVals((prev) => ({
          ...prev,
          floorNumber: [...floorNumber],
          letter: [...letter],
        }));
      }
      if (haveValue(filterCheck.floorNumber)) {
        temp = temp.filter(
          (val) => val?.floor_number == filterCheck.floorNumber
        );
        const letter = new Set();
        temp.map((val) => {
          letter.add(val?.flat_number);
        });

        setFilterVals((prev) => ({ ...prev, letter: [...letter] }));
      }
      if (haveValue(filterCheck.letter)) {
        temp = temp.filter((val) => val?.flat_number == filterCheck.letter);
      }
      if (haveValue(filterCheck.property)) {
        if (filterCheck.property === "awarded") {
          temp = temp.filter((val) => val?.is_awarded === true);
        }

        if (filterCheck.property === "available") {
          temp = temp.filter((val) => {
            if (
              !val.hasOwnProperty("is_awarded") ||
              (val.hasOwnProperty("is_awarded") && val?.is_awarded === false)
            ) {
              return true;
            }

            return false;
          });
        }
      }
      if (
        !haveValue(filterCheck.stairway) &&
        !haveValue(filterCheck.floorNumber) &&
        !haveValue(filterCheck.letter)
      ) {
        const stairway = new Set();
        const floorNumber = new Set();
        const letter = new Set();
        filteredProperty.map((val) => {
          stairway.add(val?.staircase);
          floorNumber.add(val?.floor_number);
          letter.add(val?.flat_number);
        });

        setFilterVals({
          stairway: [...stairway],
          floorNumber: [...floorNumber],
          letter: [...letter],
        });
      }

      setFilteredProperty(temp);
    }
  };

  function myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  console.log(
    "filteredPropertyfilteredPropertyfilteredProperty",
    filteredProperty,
    "filteredPropertyfilteredPropertyfilteredProperty"
  );

  const markPropertyAwarded = (propertyId) => {
    if (haveValue(propertyId) && haveValue(filteredProperty)) {
      loadData();
    }
  };

  if (landlord_loading) return <Spinner />;
  return (
    <>
      {haveValue(properties) && properties.length > 0 ? (
        <>
          <div className="tabs-box-pattern mobile">
            <div className="tabs-item d-block position-relative">
              <div
                className="number-properties"
                style={{
                  top: `${document.getElementById("header") ? document.getElementById("header").offsetHeight :  10 }px`,
                }}
              >
                <h3>
                  {_t("totalNumberOfProperties")}:{" "}
                  <span>{filteredProperty?.length}</span>
                </h3>
                <div className="search-property">
                  <input
                    type="text"
                    className="search-prop-landlord custom-input mb-0"
                    onChange={addSearchVal}
                    value={searchVal}
                    placeholder={_t("searchProperty")}
                  />
                  <a href="#">
                    <img src={searchicon} alt="" />
                  </a>

                  {/* <div class="dropdown" >
                      <button className='filterBtn' onClick={myFunction}>
                        <FaFilter class="dropbtn" />
                      </button>
                      <div id="myDropdown" class="dropdown-content">

                        <div className='filterDiv'>
                          <label htmlFor="stairwayFilter">{_t("Stairway")}</label>
                          <select name="stairwayFilter" id="stairwayFilter" onChange={e => handleFilterSelected('stairway', e)} value={filterCheck.stairway}>
                            <option value="">{_t("All")}</option>
                            {
                              filterVal.stairway.map(val => (
                                haveValue(val) ?
                                  <option value={val}>{val}</option>
                                  :
                                  <></>
                              ))
                            }
                          </select>
                        </div>
                        <div className='filterDiv'>
                          <label htmlFor="floorFilter">{_t("Floor Number")}</label>
                          <select name="floorFilter" id="floorFilter" onChange={e => handleFilterSelected('floorNumber', e)} value={filterCheck.floorNumber}>
                            <option value="">{_t("All")}</option>
                            {
                              filterVal.floorNumber.map(val => (
                                haveValue(val) ?
                                  <option value={val}>{val}</option>
                                  :
                                  <></>
                              ))
                            }
                          </select>
                        </div>
                        <div className='filterDiv'>
                          <label htmlFor="letterFilter">{_t("Letter")}</label>
                          <select name="letterFilter" id="letterFilter" onChange={e => handleFilterSelected('letter', e)} value={filterCheck.letter}>
                            <option value="">{_t("All")}</option>
                            {
                              filterVal.letter.map(val => (
                                haveValue(val) ?
                                  <option value={val}>{val}</option>
                                  :
                                  <></>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div> */}
                </div>
                <Link to="/landlord/add-property">
                  <button className="btn btngreen">
                    + {_t("addProperty")}
                  </button>
                </Link>
              </div>
              <div className="filter-div property-filter">
                <div className="filterDiv">
                  <label htmlFor="stairwayFilter">{_t("stairway")}</label>
                  <select
                    name="stairwayFilter"
                    id="stairwayFilter"
                    onChange={(e) => handleFilterSelected("stairway", e)}
                    value={filterCheck.stairway}
                  >
                    <option value="">{_t("all")}</option>
                    {filterVal.stairway.map((val) =>
                      haveValue(val) ? (
                        <option value={val}>{val}</option>
                      ) : (
                        <></>
                      )
                    )}
                  </select>
                </div>
                <div className="filterDiv">
                  <label htmlFor="floorFilter">{_t("floorNumber")}</label>
                  <select
                    name="floorFilter"
                    id="floorFilter"
                    onChange={(e) => handleFilterSelected("floorNumber", e)}
                    value={filterCheck.floorNumber}
                  >
                    <option value="">{_t("all")}</option>
                    {filterVal.floorNumber.map((val) =>
                      haveValue(val) ? (
                        <option value={val}>{val}</option>
                      ) : (
                        <></>
                      )
                    )}
                  </select>
                </div>
                <div className="filterDiv">
                  <label htmlFor="letterFilter">{_t("letter")}</label>
                  <select
                    name="letterFilter"
                    id="letterFilter"
                    onChange={(e) => handleFilterSelected("letter", e)}
                    value={filterCheck.letter}
                  >
                    <option value="">{_t("all")}</option>
                    {filterVal.letter.map((val) =>
                      haveValue(val) ? (
                        <option value={val}>{val}</option>
                      ) : (
                        <></>
                      )
                    )}
                  </select>
                </div>
                <div className="filterDiv">
                  <label htmlFor="propertyFilter">{_t("property")}</label>
                  <select
                    name="propertyFilter"
                    id="propertyFilter"
                    onChange={(e) => handleFilterSelected("property", e)}
                    value={filterCheck.property}
                  >
                    <option value="available">{_t("available")}</option>
                    <option value="all">{_t("all")}</option>
                    <option value="awarded">{_t("awarded")}</option>
                  </select>
                </div>
              </div>
              <div className="row property_margin">
                {filteredProperty &&
                  filteredProperty.map((property, index) => (
                    <SingleProperty
                      key={property?._id}
                      property={property}
                      number={index}
                      onPropertyAward={markPropertyAwarded}
                    />
                  ))}
                {
                  (!filteredProperty || filteredProperty.length === 0) &&
                  <div className="tabs-box-pattern">
                    <div className="tabs-item d-block">
                      <div className="landlord_homepage ">
                        <div className="addpropertybar">
                          <img src="../../../images/house.svg" alt="" />
                          <h4>{_t("noPropertiesYet")}</h4>
                          <p>{_t("pleaseAddYourProperties")}</p>
                          <div>
                            <button
                              className="btn btngreen"
                              onClick={(e) => navigate("/landlord/add-property")}
                            >
                              + {_t("addProperty")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tabs-box-pattern">
            <div className="tabs-item d-block">
              <div className="landlord_homepage ">
                <div className="addpropertybar">
                  <img src="../../../images/house.svg" alt="" />
                  <h4>{_t("noPropertiesYet")}</h4>
                  <p>{_t("pleaseAddYourProperties")}</p>
                  <div>
                    <button
                      className="btn btngreen"
                      onClick={(e) => navigate("/landlord/add-property")}
                    >
                      + {_t("addProperty")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Property;
